import React from "react";

const WalkingHomeSvg = props => (
  <svg
    viewBox="0 0 630 188"
    css={`
      width: 100%;
      height: ${(188 / 630) * 100 + "%"};
      vertical-align: top;
    `}
    {...props}
  >
    <title>Walking home</title>
    <g fill="#707070" fillRule="nonzero">
      <path d="M57.36 62c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm0-19a9 9 0 1 1 0 18 9 9 0 0 1 0-18zM92.71 95.54L79.1 83.39a3.38 3.38 0 0 1-.84-1.14l-9.67-12.94a12.15 12.15 0 0 0-10.34-5.82 13.66 13.66 0 0 0-7.45 2.65 4.16 4.16 0 0 0-.76.64L36.66 80.87a9.15 9.15 0 0 0-2.19 3.91L29.83 102a4.17 4.17 0 0 0 .49 3.3 4.1 4.1 0 0 0 2.73 1.9c.273.06.55.09.83.09a4.22 4.22 0 0 0 4-2.82l6.3-18 1.79-1.74-.26 39.23-12.52 16.82a6.53 6.53 0 0 0 5.24 10.42c.24.015.48.015.72 0a6.49 6.49 0 0 0 4.43-2.45l15.12-19.28 2-9.15 4.66 8.37L69 152a6.79 6.79 0 0 0 7.63 5.72 6.84 6.84 0 0 0 5.81-8.22l-11.2-51.9-.36-9.29 15.77 14a4.59 4.59 0 0 0 3 1.14 4.54 4.54 0 0 0 3-7.94l.06.03zm-49.43-9.66l-6.38 18.26a3.2 3.2 0 0 1-6.1-1.92L35.43 85a8.29 8.29 0 0 1 2-3.49l13.3-14.04a3.48 3.48 0 0 1 2.53-1.09h.06A3.49 3.49 0 0 1 56 72.09l-6.17 7.49L46 83.27l-2.72 2.61zm7.23-5.6l6.22-7.55A4.47 4.47 0 0 0 54 65.46a11 11 0 0 1 4.22-1 11.16 11.16 0 0 1 8.24 3.67l1.27 1.71a11 11 0 0 1 1.62 5.37l.86 21.91H46.86l.14-13.4 3.51-3.44zm31 69.46a5.84 5.84 0 0 1-5 7 5.82 5.82 0 0 1-6.51-4.86l-3.7-23.43-5.3-9.61 1.93-9-1-.21L57.77 129l-15 19a5.53 5.53 0 0 1-8.77-6.62l12.68-17.12.17-26.11h23.5l11.16 51.59zm10.81-48.43a3.56 3.56 0 0 1-5 .28L70.84 87l-.46-11.8a11.74 11.74 0 0 0-.3-2.23l7.29 9.75a4.48 4.48 0 0 0 1.06 1.42l13.62 12.14a3.55 3.55 0 0 1 .27 5v.03zM15.25 118.49A7.31 7.31 0 1 0 8 111.18a7.32 7.32 0 0 0 7.25 7.31zm0-13.62A6.31 6.31 0 1 1 9 111.18a6.32 6.32 0 0 1 6.25-6.31z" />
      <path d="M32.47 111.45a2.856 2.856 0 1 0-4.86-3l-3.26 5.63-6.11 5.62a6.16 6.16 0 0 0-2.7-.64 6.14 6.14 0 0 0-3.54 1.17L2.52 127 .1 134.74a2.25 2.25 0 0 0 1.75 2.87c.126.01.254.01.38 0a2.26 2.26 0 0 0 2.08-1.4L7.24 129l1.92-1.34-.76 16-4.78 6.14a3.61 3.61 0 0 0-.83 2.73A3.56 3.56 0 0 0 4.24 155a3.5 3.5 0 0 0 2.08.68 3.54 3.54 0 0 0 2.68-1.2l7.18-7.9.8-3.23 1.86 2.88 1.69 9.26a3.5 3.5 0 0 0 3.42 2.85c.13.01.26.01.39 0a3.48 3.48 0 0 0 3-4.29l-5.23-20.68-.37-8.31a6.28 6.28 0 0 0-.2-1.31h-.06l7.73-7.21 3.26-5.09zm-26 16.9l-3 7.52a1.24 1.24 0 0 1-2.2.18 1.24 1.24 0 0 1-.12-1l2.31-7.43 6.35-4.55a6.2 6.2 0 0 0-.43 2l-.16 1.33-2.75 1.95zm19.83 25.92a2.48 2.48 0 0 1-2.12 3.06 2.48 2.48 0 0 1-2.71-2L19.76 146l-2.5-4 .89-3.57-1-.24-2 7.89-7 7.71a2.56 2.56 0 0 1-3.75-3.39l5-6.41.47-10.09h11.35l5.08 20.37zm-5.56-29.17l.36 7.8H9.92l.37-7.81a5.21 5.21 0 0 1 5.22-5 5.25 5.25 0 0 1 1.92.37l-.68.63a2.58 2.58 0 0 0 1.55 4.47h.19a2.56 2.56 0 0 0 1.76-.7l.41-.37a5.7 5.7 0 0 1 .08.61zm-1.15-.94a1.5 1.5 0 0 1-1.19.42 1.52 1.52 0 0 1-1.12-.58 1.58 1.58 0 0 1 .16-2.15l7.64-7 3.4-5.85a1.82 1.82 0 0 1 1.59-.92c.106-.01.214-.01.32 0a1.84 1.84 0 0 1 1.23 2.8l-3.14 4.94-8.89 8.34z" />
      <g transform="translate(527)">
        <path d="M97.46 100.26H5.01V25.11h1v74.15h90.45V25.11h1z" />
        <path d="M35.51 25.61H0L.22 25C.72 23.45 10.14 1.25 10.54.3l.13-.3h40.57v1H11.33c-1.07 2.52-8.4 19.82-9.91 23.61h34.09v1zM102.48 25.61H67v-1h34.09C99.55 20.82 92.22 3.52 91.15 1H51.24V0h40.57l.13.3c.4 1 9.82 23.15 10.32 24.65l.22.66z" />
        <path d="M77.16 35.94L51.24 10.18 25.32 35.94l-.7-.71L51.24 8.77l26.62 26.46zM31.62 72.83H14V49.6h17.62v23.23zm-16.62-1h15.62V50.6H15v21.23zM88.49 72.83H70.86V49.6h17.63v23.23zm-16.63-1h15.63V50.6H71.86v21.23zM62.86 82.47H39.62V44.06h23.24v38.41zm-22.24-1h21.24V45.06H40.62v36.41z" />
        <path d="M28.3 99.76h-1V90.1h5.98v-8.63h17.96v1H34.28v8.63H28.3zM75.3 99.76h-1V91.1h-5.99v-8.63H51.35v-1h17.96v8.63h5.99z" />
        <path d="M33.78 90.1h35.03v1H33.78z" />
        <circle cx={45.88} cy={64.05} r={1} />
      </g>
      <g>
        <path d="M372 98.35h133.65v1H372z" />
        <path d="M381.26 99.08l-.89-.45c.15-.29 3.55-6.8 7.06-7.22.65-1.6 3.86-8.73 9.23-9a12.92 12.92 0 0 1 11.09-5.66c.16 0 7.32 1.42 7.64 7.25 1.56.38 7.17 2.06 7.48 6.34 1.57.51 7.66 2.83 7.66 8.48h-1c0-5.59-7.21-7.6-7.28-7.62l-.37-.1v-.38c0-4.36-7-5.82-7.08-5.83l-.4-.08v-.41c0-5.34-6.68-6.65-6.74-6.66a12 12 0 0 0-10.28 5.4l-.15.26h-.29c-5.4 0-8.66 8.55-8.7 8.64l-.12.32h-.34c-2.51.03-5.56 4.86-6.52 6.72z" />
        <path d="M468.11 98.85h-1c0-6.91-8.36-9.28-8.45-9.31l-.37-.1v-.38c0-5.37-8.67-7.45-8.75-7.47l-.39-.09v-.4c0-7.65-9.31-9.62-9.4-9.64-8.75 0-11.53 6.77-11.56 6.83l-.13.32h-.33a10.92 10.92 0 0 0-10.21 6.64l-.94-.35a12 12 0 0 1 10.82-7.29c.7-1.41 4.06-7.14 12.45-7.14.2 0 10 2.11 10.29 10.24 1.76.48 8.82 2.71 9.14 8 1.72.55 8.83 3.36 8.83 10.14zM473.09 39.32h1v59.54h-1z" />
        <path d="M461.448 61.89l.68-.734L473.934 72.1l-.68.733zM473.267 59.115l10.972-9.311.647.762-10.971 9.312z" />
        <path d="M473 84.25c-2.5 0-11.93-.66-16.22-10.14-1.7-.84-14.41-7.54-14.41-18.28 0-10.33 7.85-17.15 9.15-18.21.13-1.64 1.05-11.32 4.62-14.9l.71.71c-3.6 3.57-4.37 14.37-4.37 14.48v.22l-.18.14c-.09.07-8.94 6.86-8.94 17.56s13.87 17.39 14 17.46l.16.08.08.17c4.61 10.46 15.5 9.73 16 9.69 10.39 0 14.17-9.57 14.21-9.67l.1-.27h.28c.62-.1 15-2.56 15-16.13 0-13.73-9.65-20-9.75-20l-.23-.14v-.28c0-14-10.42-19.08-10.52-19.13-11.38-5.77-19.2-.11-19.27-.05l-.6-.8c.08-.06 8.36-6.1 20.31-.05a19.18 19.18 0 0 1 5.55 4.47 23 23 0 0 1 5.53 15.29 25.43 25.43 0 0 1 10 20.72c0 13.37-13.49 16.63-15.61 17.06-.72 1.61-5 10-15 10-.02-.02-.24 0-.6 0zM404.25 25.37h1v52.22h-1z" />
        <path d="M392.608 47.931l.68-.733 11.807 10.945-.68.734zM404.467 44.933l10.937-9.354.65.76-10.937 9.354z" />
        <path d="M404.13 70.3c-2.5 0-11.93-.66-16.22-10.14-1.7-.84-14.41-7.53-14.41-18.28 0-10.33 7.85-17.15 9.15-18.21.13-1.64 1-11.32 4.62-14.9l.71.71c-3.57 3.52-4.34 14.37-4.35 14.52v.22l-.18.14c-.09.07-8.94 6.86-8.94 17.56s13.86 17.39 14 17.46l.17.08.08.17c4.62 10.48 15.85 9.7 16 9.69 10.39 0 14.17-9.57 14.2-9.67l.11-.27h.28c.62-.1 15-2.56 15-16.13 0-13.73-9.64-20-9.74-20l-.24-.25v-.28c0-14.02-10.37-19.08-10.52-19.13-11.38-5.77-19.2-.11-19.28 0l-.6-.8c.09-.06 8.37-6.1 20.32-.05a19.18 19.18 0 0 1 5.55 4.47 23 23 0 0 1 5.53 15.3 25.38 25.38 0 0 1 10 20.71c0 13.37-13.49 16.64-15.6 17.06-.72 1.62-5 10.05-15 10.05-.03-.05-.25-.03-.64-.03z" />
      </g>
      <path d="M94.746 165.376l.508-.861a166.98 166.98 0 0 0 10.51 5.718l-.447.895a167.979 167.979 0 0 1-10.571-5.752zm21.555 10.72l.378-.925a154.408 154.408 0 0 0 11.245 4.084l-.305.953a155.407 155.407 0 0 1-11.318-4.111zm22.933 7.33l.229-.974a153.08 153.08 0 0 0 11.744 2.28l-.152.988a154.08 154.08 0 0 1-11.82-2.295zm23.768 3.667l.076-.997c3.947.302 7.931.46 11.953.472l-.003 1a164.003 164.003 0 0 1-12.026-.475zm24.1.087l-.066-.997c3.935-.263 7.904-.66 11.908-1.19l.131.991c-4.025.534-8.016.932-11.973 1.196zm23.79-3.162l-.204-.979c4.092-.853 7.934-1.872 11.545-3.053l.311.95c-3.647 1.193-7.524 2.221-11.652 3.082zm22.854-7.6l-.44-.899a72.535 72.535 0 0 0 10.248-6.09l.58.814a73.535 73.535 0 0 1-10.388 6.174zm19.6-13.992l-.706-.707c2.715-2.713 5.263-5.659 7.838-9.002l.792.61c-2.6 3.376-5.176 6.354-7.923 9.1zm14.904-18.875l-.827-.562c.15-.22 2.116-3.117 2.69-3.959 1.636-2.396 2.899-4.198 4.158-5.915l.807.591c-1.252 1.707-2.509 3.5-4.139 5.888-.573.84-2.54 3.737-2.69 3.957zm14.492-18.998l-.71-.704c2.95-2.978 6.05-5.48 9.463-7.572l.522.853c-3.342 2.048-6.38 4.5-9.275 7.423zm19.825-13.022l-.424-.906a121.918 121.918 0 0 1 11.147-4.575l.334.942a120.919 120.919 0 0 0-11.057 4.539zm22.523-7.967l-.239-.971a126.297 126.297 0 0 1 11.823-2.315l.145.99c-3.976.584-7.886 1.35-11.729 2.296zm23.618-3.495l-.056-.999c2.83-.158 5.69-.232 8.58-.22l-.004 1c-2.87-.012-5.71.061-8.52.219z" />
    </g>
  </svg>
);

export default WalkingHomeSvg;
