import React from "react";

const WedderburnLogoSeparated = ({ hideLogo, ...rest }) => (
  <svg
    viewBox="0 0 416 95"
    css={`
      width: 100%;
      height: ${(95 / 416) * 100 + "%"};
      vertical-align: top;
      stroke: #fff;
      fill: none;
    `}
    {...rest}
  >
    <title>Wedderburn</title>
    <path d="M64.89 0V0.66H65.05C66.2288 0.685666 67.395 0.908758 68.5 1.32C69.2055 1.63692 69.7936 2.16752 70.1812 2.83684C70.5688 3.50615 70.7363 4.28033 70.66 5.05C70.6673 5.72102 70.607 6.39106 70.48 7.05C70.37 7.61143 70.2264 8.16575 70.05 8.71L60.33 37.58L50.44 9.29C50 7.92 49.68 6.82 49.5 6C49.3459 5.43483 49.252 4.85493 49.22 4.27C49.1501 3.68164 49.2707 3.08637 49.5641 2.5716C49.8574 2.05684 50.3082 1.64971 50.85 1.41C52.1968 0.991582 53.5913 0.746277 55 0.68H55.16V0H30.62V0.66H30.78C31.9918 0.713376 33.1826 0.994971 34.29 1.49C35.2383 2.0549 36.0292 2.84928 36.59 3.8C37.3263 5.24302 37.9321 6.74902 38.4 8.3C39.73 12.06 39.68 12.2 39.56 12.51L29.68 37.87C29.6724 37.8744 29.6638 37.8767 29.655 37.8767C29.6462 37.8767 29.6376 37.8744 29.63 37.87L18.36 7.74C18.1892 7.21675 18.0555 6.68209 17.96 6.14C17.8774 5.68791 17.8339 5.22955 17.83 4.77C17.83 3.14 18.33 2.05 19.33 1.54C20.4689 1.06467 21.6785 0.78085 22.91 0.7H23.06V0H0V0.71H0.15C1.27323 0.891075 2.33838 1.33289 3.26 2C4.14 2.64 5 4.1 5.77 6.41L23.14 56V56.12H24.47L40.56 15L55.22 56V56.12H56.4L72.93 6.42C73.4162 4.55894 74.4936 2.90606 76 1.71C76.7668 1.22276 77.6182 0.883567 78.51 0.71H78.66V0H64.89Z" />
    <path d="M80.34 32C80.2988 28.5808 80.7776 25.1753 81.76 21.9C82.067 20.7356 82.7565 19.7081 83.7177 18.9827C84.6789 18.2572 85.856 17.8759 87.06 17.9C88.1758 17.8342 89.2815 18.1441 90.2005 18.7804C91.1195 19.4166 91.7988 20.3424 92.13 21.41C92.88 23.5 93.27 27 93.33 32V32.9H80.33L80.34 32ZM101.81 46.57L101.29 46.24C100.206 47.4605 98.9956 48.5632 97.68 49.53C95.9367 50.8046 93.8394 51.5037 91.68 51.53C87.19 51.53 84.02 49.02 82.26 44.07C81.2673 41.2016 80.7013 38.2029 80.58 35.17L80.52 34.23H101.78C101.78 33.61 101.66 32.85 101.54 31.95C101.283 29.461 100.607 27.0335 99.54 24.77C98.3733 22.4353 96.5572 20.4873 94.31 19.16C92.1152 17.841 89.6006 17.1493 87.04 17.16C84.8533 17.1207 82.6832 17.546 80.6731 18.408C78.6631 19.2699 76.8589 20.5488 75.38 22.16C72.16 25.5 70.53 30.3 70.53 36.42C70.53 43.19 72.23 48.16 75.6 51.19C78.5205 54.0545 82.4203 55.7024 86.51 55.8C88.9855 55.8447 91.4394 55.3321 93.69 54.3C97.0445 52.5149 99.8459 49.8449 101.79 46.58" />
    <path d="M131.64 46.19C131.64 46.8 131.37 47.8 129.27 50.19C128.608 51.0145 127.774 51.6837 126.825 52.1502C125.876 52.6168 124.837 52.8693 123.78 52.89C120.49 52.89 118.25 50.68 117.14 46.33C116.478 43.0769 116.196 39.7581 116.3 36.44C116.21 33.2428 116.506 30.0465 117.18 26.92C118.35 22.41 120.67 20.12 124.07 20.12C125.097 20.0832 126.119 20.2678 127.068 20.6612C128.017 21.0546 128.87 21.6476 129.57 22.4C131 23.89 131.64 25 131.64 26V46.19ZM138 54C139.29 53.78 142 53.47 146.27 53V52.69C144.966 52.6221 143.719 52.1306 142.72 51.29C141.89 50.46 141.48 49.09 141.48 47.1V0.18H125.39V0.44C126.982 0.424886 128.557 0.766584 130 1.44C131.1 2.13 131.64 3.66 131.64 6.11V23.56L130.1 22C128.971 20.7361 127.695 19.6115 126.3 18.65C124.69 17.6412 122.82 17.1234 120.92 17.16C116.78 17.16 113.32 18.92 110.34 22.54C107.36 26.16 105.85 31.21 105.85 37.54C105.85 43.19 107.29 47.71 110.14 50.96C112.99 54.21 116.24 55.82 120.07 55.82C122.258 55.8229 124.403 55.2207 126.27 54.08C127.783 53.0961 129.167 51.927 130.39 50.6L131.92 49V55.5C134.74 54.73 136.79 54.23 138.03 54.02" />
    <path d="M172.67 46.19C172.67 46.8 172.4 47.8 170.3 50.19C169.639 51.0132 168.806 51.6817 167.86 52.1482C166.913 52.6146 165.875 52.8679 164.82 52.89C161.52 52.89 159.28 50.68 158.17 46.33C157.517 43.0758 157.235 39.7578 157.33 36.44C157.238 33.2428 157.533 30.0462 158.21 26.92C159.38 22.41 161.7 20.12 165.1 20.12C166.127 20.0832 167.149 20.2678 168.098 20.6612C169.047 21.0546 169.9 21.6476 170.6 22.4C172 23.89 172.67 25 172.67 26V46.19ZM179.06 54C180.36 53.78 183.06 53.47 187.34 53V52.69C186.033 52.6199 184.784 52.1288 183.78 51.29C182.95 50.45 182.54 49.08 182.54 47.1V0.18H166.42V0.44C168.012 0.424886 169.587 0.766584 171.03 1.44C172.13 2.13 172.67 3.66 172.67 6.11V23.56L171.14 22C170.007 20.7369 168.728 19.6125 167.33 18.65C165.737 17.6376 163.887 17.1032 162 17.11C157.86 17.11 154.4 18.87 151.42 22.49C148.42 26.11 146.93 31.16 146.93 37.49C146.93 43.14 148.38 47.66 151.22 50.91C154.06 54.16 157.32 55.77 161.15 55.77C163.323 55.7547 165.451 55.1424 167.3 54C168.816 53.0198 170.2 51.8503 171.42 50.52L173 49V55.5C175.82 54.73 177.87 54.23 179.11 54.02" />
    <path d="M199.09 32C199.049 28.5808 199.528 25.1753 200.51 21.9C200.814 20.7338 201.502 19.7043 202.464 18.9783C203.426 18.2523 204.605 17.8723 205.81 17.9C206.926 17.8342 208.032 18.1441 208.951 18.7804C209.87 19.4166 210.549 20.3424 210.88 21.41C211.63 23.51 212 27 212.07 32V32.9H199.07L199.09 32ZM220.56 46.57L220.03 46.24C218.951 47.4623 217.744 48.5653 216.43 49.53C214.686 50.8028 212.589 51.5017 210.43 51.53C205.94 51.53 202.77 49.02 201.01 44.07C200.024 41.2001 199.462 38.202 199.34 35.17L199.28 34.23H220.53C220.49 33.61 220.41 32.85 220.29 31.95C220.056 29.4639 219.399 27.0359 218.35 24.77C217.18 22.4376 215.365 20.4904 213.12 19.16C210.925 17.841 208.411 17.1493 205.85 17.16C203.663 17.1207 201.493 17.546 199.483 18.408C197.473 19.2699 195.669 20.5488 194.19 22.16C190.97 25.5 189.33 30.3 189.33 36.42C189.33 43.19 191.04 48.16 194.41 51.19C197.331 54.0545 201.23 55.7024 205.32 55.8C207.796 55.8447 210.249 55.3321 212.5 54.3C215.855 52.5149 218.656 49.8449 220.6 46.58" />
    <path d="M254.19 18.56C253.051 17.6112 251.612 17.0974 250.13 17.11C248.14 17.0877 246.203 17.7555 244.65 19C242.742 20.5583 241.032 22.345 239.56 24.32L238 26.35V18.08H224V18.42C225.218 18.5369 226.369 19.0336 227.29 19.84C228.05 20.65 228.42 22.01 228.42 24.02L228.34 49.1C228.28 51 227.85 52.28 227.04 53.02C226.172 53.702 225.135 54.1343 224.04 54.27V54.6H244.39V54.3C242.794 54.2345 241.249 53.7217 239.93 52.82C238.93 51.94 238.42 50.09 238.42 47V31.25C238.283 28.9474 238.955 26.6694 240.32 24.81C241.61 23.24 242.97 22.44 244.32 22.44C244.95 22.44 245.79 22.78 247.73 25.26C248.158 25.8984 248.733 26.425 249.406 26.7955C250.079 27.1661 250.832 27.37 251.6 27.39C252.181 27.414 252.76 27.311 253.297 27.0882C253.834 26.8654 254.317 26.5282 254.71 26.1C255.545 25.1615 255.983 23.9351 255.93 22.68C255.987 21.9055 255.86 21.1284 255.56 20.4123C255.259 19.6962 254.793 19.0614 254.2 18.56" />
    <path d="M283.42 49.8C282.1 53.16 279.79 54.87 276.56 54.87C275.394 54.9378 274.234 54.6586 273.227 54.0677C272.22 53.4768 271.41 52.6007 270.9 51.55C270.302 50.2687 269.963 48.8824 269.9 47.47V25.53C269.9 24.71 270.45 23.66 271.7 22.14C272.294 21.391 273.057 20.7925 273.925 20.393C274.794 19.9934 275.745 19.804 276.7 19.84C280.09 19.84 282.5 21.68 283.7 25.16C284.839 28.9018 285.382 32.7994 285.31 36.71C285.31 42.29 284.69 46.71 283.48 49.8H283.42ZM291.65 22.25C290.426 20.5924 288.817 19.2581 286.961 18.3625C285.105 17.4668 283.059 17.037 281 17.11C278.845 17.0999 276.724 17.655 274.85 18.72C273.606 19.4965 272.456 20.4124 271.42 21.45L269.92 22.91V0.18H255.6V0.53C256.789 0.674779 257.911 1.16105 258.83 1.93C259.64 2.73 260.03 4.1 260.03 6.11V55.78L266.93 50.78L267.46 51.29C268.828 52.5889 270.373 53.6866 272.05 54.55C273.755 55.3808 275.633 55.7955 277.53 55.76C279.947 55.8223 282.351 55.3816 284.589 54.4658C286.827 53.55 288.85 52.1791 290.53 50.44C293.99 46.91 295.74 41.77 295.74 35.16C295.884 30.5091 294.438 25.948 291.64 22.23" />
    <path d="M329.07 54.06C330.36 53.84 333.31 53.51 338.07 53.06V52.75C336.763 52.6799 335.514 52.1888 334.51 51.35C333.68 50.51 333.28 49.14 333.28 47.16V18.08H318.48V18.35C319.901 18.3425 321.28 18.8305 322.38 19.73C323.25 20.6 323.67 22 323.67 24.02V48L323.41 48.26C322.527 49.1723 321.552 49.99 320.5 50.7C319.213 51.5858 317.692 52.0695 316.13 52.09C313.69 52.09 312.02 51.23 311.13 49.54C310.579 48.2467 310.336 46.8432 310.42 45.44V18.08H296.52V18.48C297.622 18.6979 298.653 19.1858 299.52 19.9C300.28 20.63 300.64 21.9 300.64 24.02V45C300.64 49 301.69 51.86 303.75 53.38C305.713 54.8118 308.053 55.6348 310.48 55.7468C312.907 55.8589 315.314 55.2549 317.4 54.01C319.1 53.0065 320.692 51.8303 322.15 50.5L323.62 49.23V55.45C325.393 54.8864 327.196 54.419 329.02 54.05" />
    <path d="M370.29 18.56C369.148 17.6097 367.706 17.0959 366.22 17.11C364.233 17.0867 362.299 17.7548 360.75 19C358.836 20.5552 357.123 22.3422 355.65 24.32L354.06 26.4V18.08H340.06V18.42C341.276 18.5342 342.425 19.0314 343.34 19.84C344.11 20.65 344.48 22.01 344.48 24.02L344.4 49.1C344.33 51 343.91 52.28 343.1 53.02C342.23 53.6986 341.194 54.1304 340.1 54.27V54.6H360.45V54.3C358.857 54.2326 357.316 53.7199 356 52.82C355 51.94 354.49 50.09 354.49 47V31.25C354.35 28.9487 355.019 26.6707 356.38 24.81C357.68 23.24 359.04 22.44 360.38 22.44C361.01 22.44 361.86 22.78 363.79 25.26C364.219 25.8998 364.795 26.4271 365.47 26.7978C366.146 27.1685 366.9 27.3716 367.67 27.39C368.251 27.414 368.83 27.3109 369.367 27.0882C369.904 26.8654 370.387 26.5281 370.78 26.1C371.611 25.1594 372.048 23.9345 372 22.68C372.059 21.9062 371.934 21.1294 371.635 20.4132C371.336 19.697 370.872 19.0619 370.28 18.56" />
    <path d="M389.65 23.66L388 26.12V18.12H374V18.46C375.245 18.5733 376.416 19.0975 377.33 19.95C378.07 20.78 378.42 22.12 378.42 24.06V48.46C378.42 50.64 377.98 52.1 377.05 52.92C376.19 53.63 375.153 54.0932 374.05 54.26V54.6H392.29V54.21C391.272 54.0237 390.318 53.5795 389.52 52.92C388.78 52.22 388.43 50.92 388.43 48.79V26.28L388.57 26.07C389.373 24.807 390.369 23.6768 391.52 22.72C392.762 21.5874 394.379 20.9535 396.06 20.94C397.077 20.8743 398.087 21.1452 398.935 21.7109C399.783 22.2766 400.42 23.1056 400.75 24.07C401.236 25.5457 401.45 27.0977 401.38 28.65V48.79C401.38 50.9 401.03 52.22 400.29 52.92C399.492 53.5795 398.538 54.0237 397.52 54.21V54.6H415.27V54.26C414.228 54.1198 413.239 53.7134 412.4 53.08C411.54 52.35 411.13 50.95 411.13 48.79V28.69C411.13 24.33 410.23 21.29 408.47 19.63C406.71 17.97 404.1 17.11 400.77 17.11C398.53 17.0926 396.334 17.7286 394.45 18.94C392.537 20.1562 390.904 21.7651 389.66 23.66" />
    {!hideLogo && (
      <g>
        <path d="M104.667 85.749l5.664-8.024h2.134l-5.71 8.024 6.236 8.048h-2.339l-5.985-7.956v-.092zM104.07 93.797h-1.834V77.725h1.834v16.072zM133.81 79.351v-1.626h10.387v1.626h-4.266v14.446h-1.834V79.351h-4.287zM169.257 85.749l5.664-8.024h2.134l-5.71 8.024 6.236 8.048h-2.337l-5.987-7.956v-.092zM168.662 93.797h-1.834V77.725h1.834v16.072zM183.176 81.805c0 5.069 7.659 4.242 7.659 7.889 0 1.49-1.171 2.682-3.348 2.682-1.926 0-3.211-.779-4.105-1.514l-1.079 1.469c.919.826 2.593 1.696 5.022 1.696 3.439 0 5.367-2.018 5.367-4.447 0-4.954-7.659-4.174-7.659-7.889 0-1.469 1.123-2.523 3.005-2.523a4.818 4.818 0 0 1 3.14 1.1l1.123-1.421c-.917-.756-2.338-1.351-4.149-1.351-2.89 0-4.976 1.878-4.976 4.31zM258.339 93.797V77.725h1.836V92.17h6.901v1.627h-8.737zM290.326 93.797V77.725h8.531v1.626h-6.697v5.482h5.893v1.625h-5.893v5.712h7.017v1.627h-8.851zM320.363 79.351v-1.626h10.387v1.626h-4.265v14.446h-1.835V79.351h-4.287zM218.323 79.974l-1.516-4.662c-.134-.416-.725-.416-.86 0l-1.514 4.662a2.624 2.624 0 0 1-2.498 1.815h-4.902a.452.452 0 0 0-.265.82l3.965 2.882a2.629 2.629 0 0 1 .954 2.934l-1.515 4.662a.455.455 0 0 0 .163.507.456.456 0 0 0 .533 0l3.967-2.882a2.629 2.629 0 0 1 3.086 0l3.966 2.882a.455.455 0 0 0 .533 0 .447.447 0 0 0 .164-.227.448.448 0 0 0 0-.28l-1.516-4.662a2.622 2.622 0 0 1 .955-2.934l3.966-2.883a.452.452 0 0 0-.266-.819h-4.903a2.625 2.625 0 0 1-2.497-1.815z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.145 94.027c-3.83 0-6.145-2.27-6.145-8.186 0-5.939 2.453-8.345 6.33-8.345 3.803 0 6.144 2.268 6.144 8.184 0 5.94-2.476 8.347-6.329 8.347zm4.449-8.186c0 4.976-1.675 6.535-4.335 6.535s-4.379-1.673-4.379-6.696c0-4.997 1.697-6.535 4.311-6.535 2.682 0 4.403 1.674 4.403 6.696zM123.629 94.027c-3.828 0-6.145-2.27-6.145-8.186 0-5.939 2.454-8.345 6.33-8.345 3.805 0 6.144 2.268 6.144 8.184 0 5.94-2.476 8.347-6.329 8.347zm4.45-8.186c0 4.976-1.674 6.535-4.334 6.535-2.661 0-4.381-1.673-4.381-6.696 0-4.997 1.699-6.535 4.313-6.535 2.682 0 4.402 1.674 4.402 6.696zM154.194 94.027c-3.83 0-6.147-2.27-6.147-8.186 0-5.939 2.454-8.345 6.332-8.345 3.805 0 6.143 2.268 6.143 8.184 0 5.94-2.475 8.347-6.328 8.347zm4.45-8.186c0 4.976-1.676 6.535-4.335 6.535-2.661 0-4.38-1.673-4.38-6.696 0-4.997 1.697-6.535 4.31-6.535 2.684 0 4.405 1.674 4.405 6.696zM247.586 77.723l5.205 16.074h-1.926l-1.49-4.677h-6.467l-1.489 4.677h-1.88l5.203-16.074h2.844zm1.261 9.769h-5.411l1.742-5.459.055-.18c.321-1.046.601-1.957.863-2.868h.091c.089.292.175.58.262.871.208.692.421 1.402.68 2.225l1.718 5.411zM277.692 93.797c3.828 0 5.388-1.926 5.388-4.54 0-2.316-1.306-3.508-2.98-3.714v-.093c1.285-.32 2.179-1.674 2.179-3.622 0-3.026-2.088-4.103-4.562-4.103h-4.909v16.072h4.884zm3.531-4.425c0-2.27-1.605-2.866-3.485-2.866h-3.142v5.685h3.234c2.339 0 3.393-.846 3.393-2.819zm-6.627-10.042h2.775c1.903 0 3.095.757 3.095 2.681 0 2.02-.962 2.889-3.003 2.889h-2.867v-5.57zM341.548 77.723l5.206 16.074h-1.925l-1.491-4.677h-6.467l-1.49 4.677H333.5l5.205-16.074h2.843zm1.262 9.769h-5.411l1.743-5.459.107-.35.002-.007c.298-.972.561-1.832.808-2.691h.091l.262.87c.207.692.42 1.402.679 2.226l1.719 5.411zM310.114 77.723c3.348 0 5.365 1.307 5.365 4.518 0 2.637-1.697 4.172-3.623 4.379v.092c.643.297 1.215.826 1.949 2.065l2.959 5.02h-2.179l-2.912-5.136c-.619-1.055-1.331-1.537-2.499-1.537h-1.262v6.673h-1.835V77.723h4.037zm-.137 7.82h-2.065V79.35h1.95c2.431 0 3.737.85 3.737 3.096 0 2.223-1.397 3.096-3.622 3.096z"
        />
      </g>
    )}
  </svg>
);

export default WedderburnLogoSeparated;
