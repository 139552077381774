import React from "react";

const AnthemUnitedLogo = ({ invert, ...rest }) => (
  <svg
    viewBox="0 0 141.7 31.2"
    css={`
      vertical-align: top;
    `}
    {...rest}
  >
    <title>Anthem United</title>
    <g>
      <path
        d="M6.5,10.2h7.6l6.5,20.4h-6.2l-1.1-3.8H6.5l-1.1,3.8H0L6.5,10.2z M7.7,22.8h4.5L10,14.9L7.7,22.8z"
        fill={invert ? "#fff" : "#000"}
      />
      <path
        d="M21.5,15.7h5.1v2.4c0.8-1.5,2.4-2.8,4.9-2.8c2.9,0,4.9,1.8,4.9,5.6v9.7h-5.1V22c0-1.7-0.7-2.5-2.2-2.5
			c-1.5,0-2.5,0.9-2.5,2.8v8.4h-5.1V15.7z"
        fill={invert ? "#fff" : "#000"}
      />
      <path
        d="M39.1,25.8v-6.7h-1.8v-3.5h1.8v-3.1h5.1v3.1h2.9v3.5h-2.9v6.2c0,1.1,0.5,1.6,1.5,1.6c0.6,0,1-0.1,1.5-0.3v3.9
			c-0.7,0.2-1.6,0.4-2.8,0.4C41,31,39.1,29.4,39.1,25.8z"
        fill={invert ? "#fff" : "#000"}
      />
      <path
        d="M48.6,9.1h5.1v9c0.8-1.5,2.4-2.8,4.9-2.8c2.9,0,4.9,1.8,4.9,5.6v9.7h-5.1V22c0-1.7-0.7-2.5-2.1-2.5
			c-1.5,0-2.5,0.9-2.5,2.8v8.4h-5.1V9.1z"
        fill={invert ? "#fff" : "#000"}
      />
      <path
        d="M64.7,23.4v-0.2c0-4.8,3.6-7.8,8.1-7.8c4.1,0,7.6,2.3,7.6,7.7v1.3H69.9c0.1,2,1.3,3.1,3.1,3.1
			c1.7,0,2.4-0.8,2.6-1.8h4.8c-0.5,3.4-3.1,5.3-7.6,5.3C68.1,31,64.7,28.3,64.7,23.4z M75.4,21.5c-0.1-1.8-1-2.8-2.6-2.8
			c-1.5,0-2.6,1-2.9,2.8H75.4z"
        fill={invert ? "#fff" : "#000"}
      />
      <path
        d="M81.6,15.7h5.1V18c0.8-1.4,2.4-2.7,4.7-2.7c2,0,3.3,0.9,4.1,2.5c1.3-1.7,3.1-2.5,4.9-2.5
			c2.8,0,4.9,1.8,4.9,5.6v9.8h-5.1v-8.9c0-1.5-0.7-2.3-1.9-2.3c-1.4,0-2.3,0.9-2.3,2.5v8.7H91v-8.9c0-1.5-0.7-2.3-1.9-2.3
			c-1.4,0-2.3,0.9-2.3,2.5v8.7h-5.1V15.7z"
        fill={invert ? "#fff" : "#000"}
      />
    </g>
    <g>
      <polygon
        points="121.3,10.2 111.1,10.2 121.3,0 131.5,0 		"
        fill={invert ? "#fff" : "#000"}
      />
      <polygon
        points="121.3,30.6 111.1,30.6 121.3,20.4 131.5,20.4 		"
        fill={invert ? "#fff" : "#000"}
      />
      <polygon
        points="141.7,20.4 131.5,20.4 121.3,10.2 131.5,10.2 		"
        fill={invert ? "#fff" : "#000"}
      />
    </g>
  </svg>
);

export default AnthemUnitedLogo;
