import React, { useState } from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import { fluidType } from "utils";

import MobileMenu from "../mobileMenu";
import Button from "../button";
import Section from "../section";
import { H6 } from "../headings";
import WedderburnLogo from "../wedderburnLogo";

import { linkResolver } from "utils";

const SubnavItem = ({ label, to, onClick }) => (
  <H6 color="text.reverse" onClick={onClick}>
    <Link
      children={label}
      to={to}
      activeClassName="active"
      partiallyActive={true}
      css={`
        position: relative;
        margin: 0 1em;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: -0.25em;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: currentColor;
          transform-origin: left center;
          transform: scale3d(0, 1, 1);
        }
        &.active {
          &::after {
            transform: scale3d(1, 1, 1);
          }
        }
      `}
    />
  </H6>
);

const NavItem = ({ label, to, onClick, className }) => (
  <div
    onClick={onClick}
    as={to ? Link : "div"}
    to={to}
    activeClassName="active"
    partiallyActive={true}
    className={className}
    css={`
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      &:last-child {
        margin-right: 16px;
      }
      &.active {
        > div {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      &:hover {
        > div {
          opacity: 0.5;
          transform: translate3d(0, 0, 0);
        }
      }
    `}
  >
    <H6
      children={label}
      as="span"
      color="text.reverse"
      css={`
        padding: 0 1em;
      `}
    />
    <div
      css={`
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${props => props.theme.colors.bg.default};
        opacity: 0;
        transform: translate3d(0, 100%, 0);
        transition: transform 200ms ease-out, opacity 200ms ease-out;
      `}
    />
  </div>
);

function Header({ home, revealChildren, location }) {
  const [subnav, setSubnav] = useState([]);
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        {
          prismicNavigation {
            dataString
          }
        }
      `}
      render={data => {
        let header = JSON.parse(data.prismicNavigation.dataString);
        return (
          <>
            {!home && (
              <div
                css={`
                  position: relative;
                  height: 86px;

                  @media (max-width: 60em) {
                    height: 74px;
                  }
                `}
              />
            )}
            <header
              css={`
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 1000;
                background-color: ${props => props.theme.colors.brand.rose};
                transition: opacity 500ms ease-out 500ms;
              `}
              style={{
                opacity: revealChildren ? 1 : 0
              }}
            >
              <div
                css={`
                  display: flex;
                  padding: 0 5vw;
                  background-color: ${props => props.theme.colors.brand.rose};
                `}
              >
                <Link
                  to="/"
                  css={`
                    margin: 20px 0;
                    ${fluidType(3)};
                  `}
                >
                  <WedderburnLogo
                    css={`
                      width: ${(416 / 95) * 1.5 + "em"};
                      height: 1.5em;
                      vertical-align: top;
                    `}
                    onClick={() => {
                      setSubnav([]);
                    }}
                  />
                </Link>
                <section
                  css={`
                    display: flex;
                    flex: 1;
                    justify-content: flex-end;
                    @media (max-width: 72em) {
                      display: none;
                    }
                  `}
                >
                  <nav
                    css={`
                      display: flex;
                    `}
                  >
                    {header.body.map((link, index) => (
                      <NavItem
                        label={link.primary.label}
                        to={
                          !link.items || !link.items[0]
                            ? linkResolver(link.primary.link)
                            : undefined
                        }
                        key={"fullNavLink" + index}
                        className={
                          link.items &&
                          link.items[0] &&
                          location.pathname.indexOf("/news-and-resources/") !==
                            -1 &&
                          "active"
                        }
                        onClick={() => {
                          if (link.items && link.items[0]) {
                            if (subnav.length === 0) {
                              setSubnav(link.items);
                            } else {
                              setSubnav([]);
                            }
                          } else {
                            setSubnav([]);
                          }
                        }}
                      />
                    ))}
                  </nav>
                  {header.registration_link && header.registration_button_text && (
                    <Button
                      children={header.registration_button_text}
                      as={Link}
                      to={linkResolver(header.registration_link)}
                      variant="reverseAlt"
                      style={{ alignSelf: "center" }}
                      onClick={() => {
                        setSubnav([]);
                      }}
                    />
                  )}
                </section>
                <section
                  css={`
                    display: none;
                    flex: 1;
                    justify-content: flex-end;
                    @media (max-width: 72em) {
                      display: flex;
                    }
                  `}
                >
                  <nav
                    css={`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <button
                      onClick={() => {
                        setMobileMenu(true);
                      }}
                      title="Open menu button"
                      css={`
                        width: 20px;
                        height: 20px;
                        margin: 0;
                        padding: 0;
                        border: 0;
                        border-radius: 0;
                        box-shadow: none;
                        background: transparent;
                        cursor: pointer;
                        &:focus {
                          outline: 0;
                        }
                      `}
                    >
                      <svg
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Hamburger icon</title>
                        <path
                          d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    {mobileMenu && (
                      <MobileMenu
                        setMobileMenu={setMobileMenu}
                        location={location}
                      />
                    )}
                  </nav>
                </section>
              </div>
              <div
                css={`
                  position: relative;
                `}
              >
                <Section
                  bg="brand.nickel"
                  css={`
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    transition: transform 300ms ease-in-out;
                    transform: translate3d(0, -100%, 0);
                    &.show {
                      transform: translate3d(0, 0, 0);
                    }
                  `}
                  className={subnav.length > 0 ? "show" : ""}
                >
                  {subnav.length > 0 && (
                    <nav
                      css={`
                        display: flex;
                        justify-content: center;
                        -ms-grid-column: 1;
                        -ms-grid-column-span: 23;
                        grid-column: 1 / span 12;
                        -ms-grid-row: 1;
                        grid-row: 1;
                        ${props => props.theme.mediaQueries.medium} {
                          -ms-grid-column: 1;
                          -ms-grid-column-span: 15;
                          grid-column: 1 / 9;
                        }
                        ${props => props.theme.mediaQueries.small} {
                          -ms-grid-column: 1;
                          -ms-grid-column-span: 7;
                          grid-column: 1 / 5;
                        }
                      `}
                    >
                      {subnav.map((subnav, index) => (
                        <SubnavItem
                          label={subnav.sublabel}
                          to={linkResolver(subnav.sublink)}
                          key={"fullNavLink" + index}
                          onClick={() => {
                            setSubnav([]);
                          }}
                        />
                      ))}
                    </nav>
                  )}
                </Section>
              </div>
            </header>
          </>
        );
      }}
    />
  );
}

export default Header;
