import { createGlobalStyle } from "styled-components";
import { normalize } from "polished";

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Raleway:400,400i,700,700i');
  ${normalize()};
  *, *::before, *::after { box-sizing: border-box; }
  body {
    max-width: 100%;
    font-family: ${props => props.theme.fonts.sans};
    font-size: 16px;
    color: ${props => props.theme.colors.text.default};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${props => props.theme.colors.bg.default};
    overflow-x: hidden;
    overflow-y: scroll;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;
