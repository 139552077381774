import React from "react";
import styled from "styled-components";
import { cover } from "polished";
import { color, fontFamily, fontWeight, space, textAlign } from "styled-system";

import { fluidType } from "utils";

const Comp = styled.div`
  margin: 0;
  ${space};
  ${fontFamily};
  ${(props) => fluidType(props.fontSize)};
  ${fontWeight};
  line-height: 1.4;
  ${textAlign};
  ${color};

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${(props) => props.theme.fonts.serif};
    font-weight: 700;
    letter-spacing: -0.0125em;
    line-height: 1.2;
  }
  h1 {
    ${fluidType(4)};
  }
  h2 {
    ${fluidType(3)};
  }
  h3 {
    ${fluidType(2)};
  }
  h4 {
    ${fluidType(1)};
  }
  h5 {
    ${fluidType(0.75)};
  }
  h6 {
    ${fluidType(0.67)};
    font-weight: 700;
    letter-spacing: 0.075em;
    line-height: 1;
    text-transform: uppercase;
  }

  p {
    margin: 0;
  }
  p + p {
    margin-top: 1em;
  }
  a {
    text-decoration: underline;
  }
  blockquote {
    position: relative;
    font-family: ${(props) => props.theme.fonts.serif};
    ${fluidType(3)};
    font-weight: 700;
    letter-spacing: -0.0125em;
    line-height: 1.2;
    &::before {
      content: "“";
      position: absolute;
      left: 0;
      transform: translateX(-100%);
    }
    &::after {
      content: "”";
    }
  }
  .block-img {
    width: 100%;
    > img {
      max-width: 100%;
      margin: 0 auto;
    }
  }
  [data-oembed-type="video"] {
    div {
      position: relative;
      margin: 16px 0;
      padding-bottom: ${(270 / 480) * 100}%;
      iframe {
        ${cover()};
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const RichText = ({ children, ...rest }) => <Comp {...rest}>{children}</Comp>;

RichText.defaultProps = {
  fontSize: 1,
  fontWeight: 400,
  color: "text.default",
};

export default RichText;
