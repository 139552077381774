import React, { useState, useEffect } from "react";
import { Watch } from "scrollmonitor-react";

function FadeInGroup({ children, isInViewport, style }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isInViewport && !show) {
      setShow(true);
    }
  }, [isInViewport]);

  return (
    <div
      css={`
        transition: opacity 600ms ease-in, transform 1s ease-in;
      `}
      style={{
        opacity: show ? 1 : 0,
        transform: show ? "translate3d(0, 0, 0)" : "translate3d(0, 20px, 0)",
        ...style
      }}
    >
      {children}
    </div>
  );
}

export default Watch(FadeInGroup);
