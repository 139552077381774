import React, { useState, useEffect } from "react";
import { Watch } from "scrollmonitor-react";

function FadeGroup({ children, isInViewport, style }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (isInViewport && !show) {
      setShow(true);
    }
  }, [isInViewport]);

  return (
    <div
      css={`
        transition: opacity 600ms ease-in;
      `}
      style={{ opacity: show ? 1 : 0, ...style }}
    >
      {children}
    </div>
  );
}

export default Watch(FadeGroup);
