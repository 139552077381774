function columnPush(step) {
  const breakpoints = ["40em", "64em", "80em"];
  const margin = ["5vw", "5vw", "5vw"];
  const gutter = ["5vw", "5vw", "5vw"];
  const columnCount = [4, 8, 12];

  return `
    padding-left: calc((${gutter[2]} + (((100vw - (${margin[2]} * 2)) - (${
    gutter[2]
  } * ${columnCount[2] - 1})) / ${columnCount[2]})) * ${step});

  @media screen and (max-width: ${breakpoints[1]}) {
      padding-left: calc((${gutter[1]} + (((100vw - (${margin[1]} * 2)) - (${
    gutter[1]
  } * ${columnCount[1] - 1})) / ${columnCount[1]})) * ${step});
    }

    @media screen and (max-width: ${breakpoints[0]}) {
      padding-left: calc((${gutter[0]} + (((100vw - (${margin[0]} * 2)) - (${
    gutter[0]
  } * ${columnCount[0] - 1})) / ${columnCount[0]})) * ${step});
    }
  `;
}

export default columnPush;
