import { css } from "styled-components";

const DefaultVariant = css`
  color: ${(props) => props.theme.colors.text.default};
  &::before {
    border: 1px solid ${(props) => props.theme.colors.bg.reverse};
  }
  &::after {
    background-color: ${(props) => props.theme.colors.bg.reverse};
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.text.reverse};
  }
  &.active {
    color: ${(props) => props.theme.colors.text.reverse} !important;
  }
  &.inactive {
    color: ${(props) => props.theme.colors.text.default} !important;
  }
`;

const DefaultAltVariant = css`
  && {
    color: ${(props) => props.theme.colors.text.reverse};
    &::before {
      background-color: ${(props) => props.theme.colors.bg.reverse};
    }
    &::after {
      border: 1px solid ${(props) => props.theme.colors.bg.reverse};
      transform: scaleY(1.2);
    }
    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.text.default};
      &::before {
        transform: scaleY(0);
      }
    }
  }
`;

const ReverseVariant = css`
  color: ${(props) => props.theme.colors.text.reverse};
  &::before {
    border: 1px solid ${(props) => props.theme.colors.bg.default};
  }
  &::after {
    background-color: ${(props) => props.theme.colors.bg.default};
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.text.default};
  }
`;

const ReverseAltVariant = css`
  && {
    color: ${(props) => props.theme.colors.brand.rose};
    &::before {
      background-color: ${(props) => props.theme.colors.bg.default};
    }
    &::after {
      border: 1px solid ${(props) => props.theme.colors.bg.default};
      transform: scaleY(1.2);
    }
    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.text.reverse};
      &::before {
        transform: scaleY(0);
      }
    }
  }
`;

const PrimaryVariant = css`
  color: ${(props) => props.theme.colors.brand.rose};
  &::before {
    border: 1px solid ${(props) => props.theme.colors.brand.rose};
  }
  &::after {
    background-color: ${(props) => props.theme.colors.brand.rose};
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.text.reverse};
  }
`;

const PrimaryAltVariant = css`
  && {
    color: ${(props) => props.theme.colors.text.reverse};
    &::before {
      background-color: ${(props) => props.theme.colors.brand.rose};
    }
    &::after {
      border: 1px solid ${(props) => props.theme.colors.brand.rose};
      transform: scaleY(1.2);
    }
    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.brand.rose};
      &::before {
        transform: scaleY(0);
      }
    }
  }
`;
const PeachVariant = css`
  color: ${(props) => props.theme.colors.brand.peach};
  &::before {
    border: 1px solid ${(props) => props.theme.colors.brand.peach};
  }
  &::after {
    background-color: ${(props) => props.theme.colors.brand.peach};
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.text.reverse};
  }
  &.active {
    color: ${(props) => props.theme.colors.text.reverse} !important;
  }
  &.inactive {
    color: ${(props) => props.theme.colors.brand.peach} !important;
  }
`;

const ReversePeachVariant = css`
  color: ${(props) => props.theme.colors.text.reverse};
  &::after {
    border: 1px solid ${(props) => props.theme.colors.brand.peach};
  }
  &::before {
    background-color: ${(props) => props.theme.colors.brand.peach};
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.brand.peach};
  }
  &.active {
    color: ${(props) => props.theme.colors.text.reverse} !important;
  }
  &.inactive {
    color: ${(props) => props.theme.colors.brand.peach} !important;
  }
`;

const ButtonStyles = css`
  ${(props) => props.variant === "default" && DefaultVariant};
  ${(props) => props.variant === "defaultAlt" && DefaultAltVariant};
  ${(props) => props.variant === "reverse" && ReverseVariant};
  ${(props) => props.variant === "reverseAlt" && ReverseAltVariant};
  ${(props) => props.variant === "primary" && PrimaryVariant};
  ${(props) => props.variant === "primaryAlt" && PrimaryAltVariant};
  ${(props) => props.variant === "peach" && PeachVariant};
  ${(props) => props.variant === "reversePeach" && ReversePeachVariant};
`;

export default ButtonStyles;
