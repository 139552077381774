function modularScale(base, step, ratio) {
  if (step >= 1) {
    let decimal = step - Math.trunc(step);
    let result = 1;

    for (let count = 1; count <= step; count++) {
      result = result * ratio;
    }

    result = result + result * decimal;

    return (base * result) / ratio;
  } else {
    return base * step;
  }
}

export default modularScale;
