import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { H6 } from "../headings";

const ScrollToTop = () => (
  <div
    css={`
      position: fixed;
      left: 5vw;
      bottom: 0;
      z-index: 9999;
    `}
  >
    <AnchorLink href="#top">
      <div
        css={`
          padding: 16px;
          background-color: #333;
          ${props => props.theme.mediaQueries.medium} {
            padding: 12px;
          }
        `}
      >
        <H6 children={`Scroll to top`} color="text.reverse" />
      </div>
    </AnchorLink>
  </div>
);

export default ScrollToTop;
