import styled from "styled-components";
import { color, space, textAlign } from "styled-system";

import { fluidType } from "utils";

export const H1 = styled.h1`
  margin: 0;
  ${space};
  font-family: ${props => props.theme.fonts.serif};
  ${props => fluidType(props.fontSize)};
  font-weight: 700;
  letter-spacing: -0.0125em;
  line-height: 1;
  ${textAlign};
  ${color};
`;

H1.defaultProps = {
  fontSize: 4,
  color: "text.default"
};

export const H2 = styled.h2`
  margin: 0;
  ${space};
  font-family: ${props => props.theme.fonts.serif};
  ${props => fluidType(props.fontSize)};
  font-weight: 700;
  letter-spacing: -0.0125em;
  line-height: 1;
  ${textAlign};
  ${color};
`;

H2.defaultProps = {
  fontSize: 3,
  color: "text.default"
};

export const H3 = styled.h3`
  margin: 0;
  ${space};
  font-family: ${props => props.theme.fonts.serif};
  ${props => fluidType(props.fontSize)};
  font-weight: 700;
  letter-spacing: -0.0125em;
  line-height: 1;
  ${textAlign};
  ${color};
`;

H3.defaultProps = {
  fontSize: 2,
  color: "text.default"
};

export const H4 = styled.h4`
  margin: 0;
  ${space};
  font-family: ${props => props.theme.fonts.serif};
  ${props => fluidType(props.fontSize)};
  font-weight: 700;
  letter-spacing: -0.0125em;
  line-height: 1;
  ${textAlign};
  ${color};
`;

H4.defaultProps = {
  fontSize: 1,
  color: "text.default"
};

export const H5 = styled.h5`
  margin: 0;
  ${space};
  font-family: ${props => props.theme.fonts.serif};
  ${props => fluidType(props.fontSize)};
  font-weight: 700;
  letter-spacing: -0.0125em;
  line-height: 1.2;
  ${textAlign};
  ${color};
`;

H5.defaultProps = {
  fontSize: 0.75,
  color: "text.default"
};

export const H6 = styled.h6`
  margin: 0;
  ${space};
  ${props => fluidType(props.fontSize)};
  font-weight: 700;
  letter-spacing: 0.075em;
  line-height: 1;
  text-transform: uppercase;
  ${textAlign};
  ${color};
`;

H6.defaultProps = {
  fontSize: 0.67,
  color: "text.default"
};
