import React, { useState } from "react";
import AriaModal from "react-aria-modal";
import { graphql, StaticQuery, Link } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { fluidType, serializer, linkResolver } from "utils";

import AnthemUnitedLogo from "../anthemUnitedLogo";
import RichText from "../richText";
import Button from "../button";
import { H3 } from "../headings";
import Section from "../section";
import Text from "../text";
import MapSvg from "./mapSvg";
import Drawer from "./drawer";

function Footer({ preview }) {
  const [modalActive, setModalActive] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        {
          prismicFooter {
            dataString
            data {
              communities {
                community_logo {
                  fluid(maxWidth: 400) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let footer = JSON.parse(data.prismicFooter.dataString);
        return (
          <footer>
            <Section
              pt={2}
              pb={2}
              bg="brand.navy"
              css={`
                padding-bottom: calc(5vw + 44px);
                ${(props) => props.theme.mediaQueries.small} {
                  padding-bottom: calc(15vw + 44px);
                }
              `}
            >
              <div
                css={`
                  display: flex;
                  flex-direction: column;
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                  -ms-grid-row: 1;
                  grid-row: 1;
                  padding: 2.5vw 0;
                  ${(props) => props.theme.mediaQueries.medium} {
                    grid-column: 1 / 5;
                    padding: 0;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    grid-column: 1 / 5;
                  }
                `}
              >
                <H3
                  children={
                    footer.showhome_title &&
                    PrismicRichText.asText(footer.showhome_title)
                  }
                  mb="1em"
                  color="text.reverse"
                  css={`
                    text-transform: uppercase;
                  `}
                />
                <dl
                  css={`
                    display: -ms-grid;
                    display: grid;
                    -ms-grid-columns: 1fr;
                    grid-template-columns: 1fr;
                    align-items: center;
                    margin: 0;
                    margin-bottom: 2em;
                    ${fluidType(0.85)};
                    line-height: 1.4;
                    color: ${(props) => props.theme.colors.text.reverse};
                  `}
                >
                  {footer.hours.map((hour, index) => (
                    <span key={"footerHour" + index}>
                      <dt
                        css={`
                          -ms-grid-column: 1;
                          grid-column: 1;
                          -ms-grid-row: ${index * 2 + 1};
                          grid-row: ${index * 2 + 1};
                          margin-bottom: 0.1em;
                          font-weight: 600;
                        `}
                      >
                        {hour.hour_category}
                      </dt>
                      <dt
                        css={`
                          -ms-grid-column: 1;
                          grid-column: 1;
                          -ms-grid-row: ${index * 2 + 2};
                          grid-row: ${index * 2 + 2};
                          margin-bottom: 0.75em;
                        `}
                      >
                        {hour.hour_range}
                      </dt>
                    </span>
                  ))}
                </dl>
                <div
                  css={`
                    margin-top: auto;
                  `}
                >
                  <Button
                    children={`View showhomes`}
                    as={Link}
                    to="/showhomes"
                    variant="reverse"
                  />
                </div>
              </div>
              <div
                css={`
                  display: flex;
                  flex-direction: column;
                  -ms-grid-column: 9;
                  -ms-grid-column-span: 7;
                  grid-column: 5 / 9;
                  -ms-grid-row: 1;
                  grid-row: 1;
                  padding: 2.5vw 0;
                  ${(props) => props.theme.mediaQueries.medium} {
                    grid-column: 5 / 9;
                    padding: 0;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                    -ms-grid-row: 2;
                    grid-row: 2;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .small} and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                    margin-top: 15vw;
                  }
                `}
              >
                <H3
                  children={
                    footer.directions_title &&
                    PrismicRichText.asText(footer.directions_title)
                  }
                  mb="1em"
                  color="text.reverse"
                  css={`
                    text-transform: uppercase;
                  `}
                />
                <RichText
                  children={
                    footer.directions_description &&
                    PrismicRichText.render(
                      footer.directions_description,
                      linkResolver,
                      serializer
                    )
                  }
                  mb="2em"
                  fontSize={0.85}
                  color="text.reverse"
                />
                <div
                  css={`
                    margin-top: auto;
                  `}
                >
                  <Button
                    as="a"
                    href="https://www.google.com/maps/place/Banister+Gate,+Okotoks,+AB/@50.7392793,-113.9752281,17z/data=!3m1!4b1!4m5!3m4!1s0x537199b1184d7313:0x3127a2750e0731f3!8m2!3d50.7392602!4d-113.9739286"
                    target="_blank"
                    rel="noopener noreferrer"
                    children={`Driving directions`}
                    variant="reverse"
                  />
                </div>
              </div>
              <div
                css={`
                  display: flex;
                  align-items: center;
                  -ms-grid-column: 17;
                  -ms-grid-column-span: 8;
                  grid-column: 9 / 13;
                  -ms-grid-row: 1;
                  grid-row: 1;
                  ${(props) => props.theme.mediaQueries.medium} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                    -ms-grid-row: 2;
                    grid-row: 2;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .medium} and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                    margin-top: 5vw;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                    -ms-grid-row: 3;
                    grid-row: 3;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .small} and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                    margin-top: 15vw;
                  }
                `}
              >
                <div
                  css={`
                    width: 100%;
                    height: 100%;
                    max-width: 256px;
                    margin: auto;
                    ${(props) => props.theme.mediaQueries.medium} {
                      margin: 0;
                    }
                  `}
                >
                  <MapSvg
                    css={`
                      width: 100%;
                      height: ${(285 / 308) * 100 + "%"};
                      vertical-align: top;
                    `}
                  />
                </div>
              </div>
            </Section>
            <Drawer footer={footer} data={data} preview={preview} />
            <section
              css={`
                padding-top: 48px;
                padding-right: 5vw;
                padding-bottom: 48px;
                padding-left: 5vw;
                font-family: -apple-system, BlinkMacSystemFont, “Roboto”,
                  “Droid Sans”, “Helvetica Neue”, Helvetica, Arial, sans-serif;
                color: #fff;
                background-color: #000;
              `}
            >
              <div
                css={`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <a
                  href="https://anthemproperties.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AnthemUnitedLogo
                    invert
                    css={`
                      width: ${(2000 / 787.75) * 56 + "px"};
                      height: 56px;
                      ${(props) => props.theme.mediaQueries.small} {
                        width: ${(2000 / 787.75) * 48 + "px"};
                        height: 48px;
                      }
                    `}
                  />
                </a>
                <div
                  css={`
                    display: -ms-grid;
                    display: grid;
                    -ms-grid-columns: 1fr 48px 1fr;
                    grid-template-columns: 1fr 48px 1fr;
                    ${(props) => props.theme.mediaQueries.small} {
                      -ms-grid-columns: 1fr 24px 1fr;
                      grid-template-columns: 1fr 24px 1fr;
                    }
                  `}
                >
                  <Text
                    color="inherit"
                    css={`
                      display: flex;
                      align-items: center;
                      -ms-grid-column: 1;
                      grid-column: 1;
                      -ms-grid-row: 1;
                      grid-row: 1;
                      height: 24px;
                    `}
                    style={{ fontSize: 14 }}
                  >
                    <a
                      children={`Facebook`}
                      href="https://www.facebook.com/TheAnthemWay/"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </Text>
                  <Text
                    color="inherit"
                    css={`
                      display: flex;
                      align-items: center;
                      -ms-grid-column: 3;
                      grid-column: 3;
                      -ms-grid-row: 1;
                      grid-row: 1;
                      height: 24px;
                    `}
                    style={{ fontSize: 14 }}
                  >
                    <a
                      children={`Instagram`}
                      href="https://www.instagram.com/theanthemway/"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </Text>
                  <Text
                    color="inherit"
                    css={`
                      display: flex;
                      align-items: center;
                      -ms-grid-column: 1;
                      grid-column: 1;
                      -ms-grid-row: 2;
                      grid-row: 2;
                      height: 24px;
                    `}
                    style={{ fontSize: 14 }}
                  >
                    <a
                      children={`Twitter`}
                      href="https://twitter.com/TheAnthemWay"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </Text>
                  <Text
                    color="inherit"
                    css={`
                      display: flex;
                      align-items: center;
                      -ms-grid-column: 3;
                      grid-column: 3;
                      -ms-grid-row: 2;
                      grid-row: 2;
                      height: 24px;
                    `}
                    style={{ fontSize: 14 }}
                  >
                    <a
                      children={`LinkedIn`}
                      href="https://www.linkedin.com/company/anthem-properties/"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </Text>
                </div>
              </div>
              <Text
                color="inherit"
                css={`
                  padding-top: 48px;
                  padding-bottom: env(safe-area-inset-bottom);
                `}
                style={{ fontSize: 10 }}
              >
                © {new Date().getFullYear()} Anthem. All Rights Reserved.{" "}
                <span
                  onClick={() => {
                    setModalActive(true);
                  }}
                  css={`
                    cursor: pointer;
                    text-decoration: underline;
                  `}
                >
                  Disclaimer
                </span>{" "}
                ·{" "}
                <a
                  href="https://anthemproperties.com/terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                  css={`
                    text-decoration: underline;
                  `}
                >
                  Privacy
                </a>
              </Text>
            </section>
            {modalActive && (
              <AriaModal
                titleText="demo three"
                onExit={() => {
                  setModalActive(false);
                }}
                focusDialog={true}
                escapeExits={false}
                underlayStyle={{ padding: "1em" }}
                aria-describedby="describer"
                data-test-id="test-id"
              >
                <div
                  id="demo-one-modal"
                  style={{
                    background: "#fff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  css={`
                    @media screen and (max-width: 40em) {
                      width: 90%;
                    }
                  `}
                >
                  <div style={{ padding: "3em" }}>
                    <Text>
                      The developer (2015 United Alberta Lands LP - Wedderburn
                      Project) reserves the right to make changes and
                      modifications to the information contained herein. Maps,
                      views, photography, and renderings are representational
                      only and are not necessarily accurate, and final design,
                      construction, and features may differ. Floor plans,
                      layouts, finishes, prices, and availability are subject to
                      change without notice. Please contact a developer sales
                      representative for details. This is not an offering for
                      sale, as an offering can only be made after the filing of
                      a disclosure statement, and only in jurisdictions where
                      qualified in accordance with applicable local laws. E. &
                      O.E.
                    </Text>
                    <button
                      style={{
                        marginTop: "2rem",
                        border: "none",
                        color: "white",
                        background: "#000",
                        padding: "8px 12px",
                      }}
                      onClick={() => setModalActive(false)}
                    >
                      <Text color="white">Close</Text>
                    </button>
                  </div>
                </div>
              </AriaModal>
            )}
          </footer>
        );
      }}
    />
  );
}

export default Footer;
