import styled from "styled-components";
import { color } from "styled-system";

export const Wash = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  ${color};
`;
