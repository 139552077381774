const breakpoints = ["40em", "64em", "80em"];

const mediaQueries = {
  small: `@media screen and (max-width: ${breakpoints[0]})`,
  medium: `@media screen and (max-width: ${breakpoints[1]})`,
  large: `@media screen and (max-width: ${breakpoints[2]})`,
};

const colors = {
  text: {
    default: "#707070",
    reverse: "#FFF",
  },
  bg: {
    default: "#FFF",
    wash: "#F7F7F7",
    reverse: "#707070",
  },
  brand: {
    rose: "#7CA1B7",
    nickel: "#707070",
    artichoke: "#A39779",
    chestnut: "#D9B9B7",
    aqua: "#C3D4E9",
    peach: "#F79A6F",
    navy: "#00325D",
  },
};

const space = [0, 5, 10, 20, 30, 40, 50, 60, 80, 100];

const fonts = {
  serif: `"Times", 'Times New Roman', Times, serif`,
  sans: `"Raleway", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;`,
  mono: `monospace`,
};

const margin = "5vw";
const gutter = "5vw";

const theme = {
  breakpoints,
  mediaQueries,
  colors,
  space,
  fonts,
  margin,
  gutter,
};

export default theme;
