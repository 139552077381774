function linkResolver(doc) {
  if (doc) {
    switch (doc.type) {
      case "home":
        return "/";
      case "contact":
        return "/contact-us/";
      case "news":
        return "/news-and-resources/news/";
      case "architectural_guidelines":
        return "/news-and-resources/architectural-guidelines/";
      case "gallery":
        return "/news-and-resources/gallery/";
      case "faqs":
        return "/news-and-resources/faqs/";
      case "news_post":
        return `/news/${doc.uid}`;
      default:
        if (doc.uid) {
          return `/${doc.uid}`;
        }

        return `/${doc.type ? doc.type.replace(/_/g, "-") : ""}`;
    }
  }

  return "/";
}

export default linkResolver;
