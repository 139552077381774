import React from "react";
import PropTypes from "prop-types";

import Image from "../image";
import Section from "../section";

import { Wash } from "./style";

const SplitImage = ({ bg, fluid, image, alt, src, topBg, ...rest }) => (
  <Section pb={2} bg={topBg ? topBg : "bg.default"} {...rest}>
    <div
      css={`
        -ms-grid-column: 3;
        -ms-grid-column-span: 19;
        grid-column: 2 / span 10;
        -ms-grid-row: 1;
        grid-row: 1;
        ${(props) => props.theme.mediaQueries.medium} {
          -ms-grid-column: 1;
          -ms-grid-column-span: 15;
          grid-column: 1 / 9;
        }
        ${(props) => props.theme.mediaQueries.small} {
          -ms-grid-column: 1;
          -ms-grid-column-span: 7;
          grid-column: 1 / 5;
          margin-right: -10vw;
          margin-left: -10vw;
        }
      `}
    >
      <Wash bg={bg} />
      <Image
        fluid={fluid}
        image={image}
        src={src}
        alt={alt}
        style={{ width: "100%" }}
      />
    </div>
  </Section>
);

Section.propTypes = {};

SplitImage.defaultProps = {
  bg: "brand.artichoke",
  image: "momArrangingFlowers",
};

export default SplitImage;
