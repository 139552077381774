import React from "react";

import { Wrapper, Input, Control, Handle } from "./style";

const Toggle = ({ children, ...props }) => (
  <Wrapper>
    <Input {...props} />
    {children}
    <div
      css={`
        position: relative;
        width: 40px;
        height: 24px;
        margin-left: 16px;
      `}
    >
      <Control>
        <Handle />
      </Control>
    </div>
  </Wrapper>
);

export default Toggle;
