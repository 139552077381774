import React from "react";
import styled, { keyframes } from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { fluidType } from "utils";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 8px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const StyledAccordion = styled(Accordion)`
  .accordion__item {
    border-bottom: 1px solid ${(props) => props.theme.colors.text.default};
    &:first-child {
      border-top: 1px solid ${(props) => props.theme.colors.text.default};
    }
  }
  .accordion__button {
    position: relative;
    width: 100%;
    padding-top: 24px;
    padding-right: 50px;
    padding-bottom: 24px;
    padding-left: 64px;
    border: none;
    ${fluidType(1)};
    font-weight: 700;
    color: ${(props) => props.theme.colors.brand.purple};
    background-color: transparent;
    user-select: none;
    cursor: pointer;

    &[aria-expanded="true"]::before,
    &[aria-selected="true"]::before {
      transform: rotate(45deg);
    }

    &:focus {
      outline: none;
    }
  }
  .accordion__panel {
    padding-bottom: 32px;
    padding-left: 64px;
    animation: ${fadeIn} 350ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const Count = styled.span`
  position: absolute;
  top: 24px;
  left: 0;
  font-weight: 400;
  font-variant-numeric: tabular-nums;
`;

const Control = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.text.default};
    transform: translateY(-50%);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.text.default};
    transform: translateY(-50%) rotate(90deg);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  ${StyledAccordion} .accordion__button[aria-expanded="true"] &::after {
    transform: translateY(-50%) rotate(90deg) scaleX(0);
  }
`;

function AccordionGroup({ id, preExpanded, items }) {
  return (
    <StyledAccordion allowZeroExpanded={true} preExpanded={preExpanded}>
      {items.map((item, index) => (
        <AccordionItem
          key={"accordionGroup" + id + index}
          uuid={"accordionGroup" + id + index}
          id={item.id}
        >
          <AccordionItemHeading>
            <AccordionItemButton>
              <Count children={(index < 9 ? "0" : "") + (index + 1)} />
              {item.heading}
              <Control />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>{item.children}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </StyledAccordion>
  );
}

export default AccordionGroup;

AccordionGroup.defaultProps = {
  id: "accordion",
  items: [],
};
