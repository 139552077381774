import styled from "styled-components";
import { width } from "styled-system";

import { buttonStyles, fluidType } from "utils";

const timing = 300;
const bezierCurve = "cubic-bezier(0.215, 0.61, 0.355, 1)";

export const Wrapper = styled.button`
  position: relative;
  z-index: 0;

  display: inline-block;
  ${width};
  ${props => props.theme.mediaQueries.small} {
    width: 100%;
  }
  margin: 0;
  padding: 1em 2em;
  ${props => props.theme.mediaQueries.small} {
    padding: 2em 2em;
  }
  border: 0;
  border-radius: 0;

  ${fluidType(0.67)};
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.075em;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: top;

  ${buttonStyles};

  background-color: transparent;

  cursor: pointer;
  overflow: visible;
  appearance: none;
  user-select: none;

  transition: color ${timing * 2 + "ms"} ${bezierCurve};

  &:focus {
    outline: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: scaleY(1);
    transform-origin: center bottom;
    transition: opacity ${timing + "ms"} ${bezierCurve},
      transform ${timing + "ms"} ${bezierCurve};
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: center bottom;
    transition: opacity ${timing + "ms"} ${bezierCurve},
      transform ${timing + "ms"} ${bezierCurve};
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 0;
      transform: scaleY(1.2);
    }
    &::after {
      opacity: 1;
      transform: scaleY(1);
    }
  }

  &.active {
    &::before {
      opacity: 0 !important;
      transform: scaleY(1.2) !important;
    }
    &::after {
      opacity: 1 !important;
      transform: scaleY(1) !important;
    }
  }
  &.inactive {
    &::before {
      opacity: 1 !important;
      transform: scaleY(1) !important;
    }
    &::after {
      opacity: 0 !important;
      transform: scaleY(0) !important;
    }
  }
`;
