import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./style";

const Section = ({ bg, children, pt, pb, ...rest }) => (
  <Wrapper
    bg={bg}
    css={`
      position: relative;

      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${props => props.theme.gutter};
      
      padding-top: ${
        pt === 3 ? "10vw" : pt === 2 ? "5vw" : pt === 1 ? "2.5vw" : 0
      };
      padding-right ${props => props.theme.margin};
      padding-bottom: ${
        pb === 3 ? "10vw" : pb === 2 ? "5vw" : pb === 1 ? "2.5vw" : 0
      };
      padding-left ${props => props.theme.margin};

      ${props => props.theme.mediaQueries.medium} {
        -ms-grid-columns: 1fr ${props => props.theme.gutter} 1fr
          ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
          ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
          ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr;
        grid-template-columns: repeat(8, 1fr);
      }

      ${props => props.theme.mediaQueries.small} {
        -ms-grid-columns: 1fr ${props => props.theme.gutter} 1fr
          ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 15vw;

        padding-top: ${
          pt === 3 ? "15vw" : pt === 2 ? "15vw" : pt === 1 ? "5vw" : 0
        };
        padding-right 10vw;
        padding-bottom: ${
          pb === 3 ? "15vw" : pb === 2 ? "15vw" : pb === 1 ? "5vw" : 0
        };
        padding-left 10vw;
      }
    `}
    {...rest}
  >
    {children}
  </Wrapper>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  pt: PropTypes.number.isRequired,
  pb: PropTypes.number.isRequired
};

Section.defaultProps = {
  bg: "bg.default",
  pt: 0,
  pb: 0
};

export default Section;
