import modularScale from "utils/modularScale";

const unit = "px";

function fluidType(value) {
  // Set environment variables
  const min = { base: 16, ratio: 1.2, viewportWidth: 320 };
  const max = { base: 22, ratio: 1.4, viewportWidth: 2560 };

  let minSize = modularScale(min.base, value, min.ratio);
  let maxSize = modularScale(max.base, value, max.ratio);

  // Transform variables
  const a = minSize;
  const b = min.viewportWidth / 100;
  const c =
    (100 * (maxSize - minSize)) / (max.viewportWidth - min.viewportWidth);

  return `
    font-size: calc(${a}${unit} + ((1vw - ${b}${unit}) * ${c}));
    @media (min-width: ${max.viewportWidth}${unit}) {
      font-size: ${maxSize}${unit};
    }
    @media (max-width: ${min.viewportWidth}${unit}) {
      font-size: ${minSize}${unit};
    }
  `;
}

export default fluidType;
