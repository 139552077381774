import styled from "styled-components";
import { color, fontFamily, fontWeight, space, textAlign } from "styled-system";

import { fluidType } from "utils";

const Text = styled.p`
  margin: 0;
  ${space};
  ${fontFamily};
  ${props => fluidType(props.fontSize)};
  ${fontWeight};
  line-height: 1.4;
  ${textAlign};
  ${color};
`;

Text.defaultProps = {
  fontSize: 1,
  fontWeight: 400,
  color: "text.default"
};

export default Text;
