import React, { useState, useRef } from "react";

import { H6, Image, Text } from "components";

function Drawer({ footer, data, preview }) {
  const [openAmount, setOpenAmount] = useState(undefined);
  const wrapperRef = useRef(null);

  const toggle = function () {
    if (!openAmount) {
      if (wrapperRef && wrapperRef.current) {
        setOpenAmount(wrapperRef.current.scrollHeight);
      }
    } else {
      setOpenAmount(undefined);
    }
  };

  return (
    <>
      {footer.communities && footer.communities[0] && (
        <>
          <section
            css={`
              position: relative;
              z-index: 1;
              margin-top: -44px;
              font-family: -apple-system, BlinkMacSystemFont, “Roboto”,
                “Droid Sans”, “Helvetica Neue”, Helvetica, Arial, sans-serif;
              color: #fff;
              transition: background-color 500ms ease-out;
            `}
          >
            <button
              css={`
                display: flex;
                justify-content: center;
                width: 100%;
                max-width: 330px;
                margin: 0 auto;
                padding-top: 16px;
                padding-bottom: 16px;
                border: 0;
                background-color: #000;
                transition: background-color 200ms ease-out;
                cursor: pointer;
                &:focus {
                  outline: 0;
                }
              `}
              style={{
                backgroundColor: openAmount && "#fff",
              }}
              onClick={toggle}
            >
              <H6
                color="#fff"
                style={{
                  fontSize: 12,
                  color: openAmount && "#000",
                  transition: "color 200ms ease-out",
                }}
              >
                Explore other communities
              </H6>
            </button>
          </section>
          <div
            ref={wrapperRef}
            css={`
              display: flex;
              justify-content: center;
              overflow: hidden;
              max-height: 0;
              transition: 500ms max-height ease-out;
            `}
            style={{
              maxHeight: openAmount ? openAmount : "",
              backgroundColor: openAmount && "#fff",
            }}
          >
            <div
              css={`
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                align-content: center;
                width: 100%;
                height: ${Math.ceil(footer.communities.length / 5) * 144 +
                96 +
                "px"};
                padding: 48px 5vw;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-columns: 1fr 1fr;
                  grid-template-columns: 1fr 1fr;
                  height: ${Math.ceil(footer.communities.length / 2) * 144 +
                  96 +
                  "px"};
                }
              `}
            >
              {footer.communities.map((community, index) => (
                <div
                  css={`
                    display: flex;
                    -ms-grid-column: ${(index % 5) + 1};
                    grid-column: ${(index % 5) + 1};
                    -ms-grid-row: ${Math.ceil((index + 1) / 5)};
                    grid-row: ${Math.ceil((index + 1) / 5)};
                    height: 144px;
                    ${(props) => props.theme.mediaQueries.medium} {
                      -ms-grid-column: ${(index % 2) + 1};
                      grid-column: ${(index % 2) + 1};
                      -ms-grid-row: ${Math.ceil((index + 1) / 2)};
                      grid-row: ${Math.ceil((index + 1) / 2)};
                    }
                  `}
                  key={"communityFooter" + index}
                >
                  <a
                    href={community.website && community.website.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={`
                      display: block;
                      width: 50%;
                      height: 67%;
                      margin: auto;
                      font-family: -apple-system, BlinkMacSystemFont, “Roboto”,
                        “Droid Sans”, “Helvetica Neue”, Helvetica, Arial,
                        sans-serif;
                      transition: filter 300ms ease-out;

                      img {
                        filter: gray; /* IE6-9 */
                        -webkit-filter: grayscale(
                          1
                        ); /* Google Chrome, Safari 6+ & Opera 15+ */
                        filter: grayscale(
                          1
                        ); /* Microsoft Edge and Firefox 35+ */
                      }

                      /* Disable grayscale on hover */
                      img:hover {
                        -webkit-filter: grayscale(0);
                        filter: none;
                      }
                    `}
                  >
                    <Image
                      src={
                        preview
                          ? community.community_logo &&
                            community.community_logo.url
                          : undefined
                      }
                      fluid={
                        preview
                          ? undefined
                          : data.prismicFooter.data.communities[index]
                              .community_logo &&
                            data.prismicFooter.data.communities[index]
                              .community_logo.fluid
                      }
                      alt={
                        community.community_logo && community.community_logo.alt
                      }
                      style={{ width: "100%", height: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                      objectFit="contain"
                    />
                    <Text
                      children={community.community && community.community}
                      style={{
                        fontFamily: "inherit",
                        fontSize: 12,
                        letterSpacing: "0.075em",
                        color: "#000",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Drawer;
