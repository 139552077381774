import styled from "styled-components";
import { color } from "styled-system";

export const Wash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 25%;
  left: 0;
  ${color};
`;
