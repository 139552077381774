import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

const Image = ({ src, fluid, image, ...props }) => (
  <>
    {src && (
      <img
        src={src}
        style={{ objectFit: "contain", ...props.style, ...props.imgStyle }}
      />
    )}
    {!src && fluid && <Img loading="eager" fluid={fluid} {...props} />}
  </>
);

export default Image;
