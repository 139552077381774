import styled from "styled-components";
import { hideVisually, rgba } from "polished";

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const Input = styled.input.attrs({
  type: "checkbox"
})`
  ${hideVisually};
`;

export const Control = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 24px;
  border-radius: 9999px;
  background-color: #666;
  user-select: none;
  transition: background-color 125ms ease-out;

  ${Input}:focus ~ div & {
    box-shadow: inset 0 0 0 2px #33cc66;
  }
  ${Input}:checked ~ div & {
    background-color: #33cc66;
    box-shadow: inset 0 0 0 2px transparent;
  }
  ${Input}:active ~ div & {
    background-color: ${rgba("#33cc66", 1 / 2)};
    box-shadow: inset 0 0 0 2px transparent;
  }
`;

export const Handle = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translateX(2px);
  background: #fff;
  transition: transform 125ms ease-out;
  ${Input}:checked ~ div & {
    transform: translateX(calc(100% - 2px));
  }
`;
