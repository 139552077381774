import React from "react";

import { H6 } from "../headings";
import Toggle from "../toggle";

const DebugMode = ({ debugMode, setDebugMode }) => (
  <div
    css={`
      position: fixed;
      right: 5vw;
      bottom: 0;
      z-index: 9999;
    `}
  >
    <div
      css={`
        padding: 16px;
        background-color: #333;
        ${props => props.theme.mediaQueries.medium} {
          padding: 12px;
        }
      `}
    >
      <Toggle
        checked={debugMode}
        onClick={() => {
          setDebugMode(!debugMode);
        }}
      >
        <H6 children={`Debug mode`} color="text.reverse" />
      </Toggle>
    </div>
  </div>
);

export default DebugMode;
