import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./style";

const Button = ({ children, variant, ...rest }) => (
  <Wrapper variant={variant} {...rest}>
    {children}
  </Wrapper>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired
};

Button.defaultProps = {
  variant: "default"
};

export default Button;
