import React, { useState, useEffect } from "react";
import posed, { PoseGroup } from "react-pose";
import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";

import {
  DebugMode,
  Footer,
  Header,
  Ruler,
  PageLoader,
  ScrollToTop
} from "components";

import "./fonts.css";
import theme from "./theme";
import { GlobalStyles } from "./style";

const transitionDuration = 600;
const transitionDelay = 200;

const Transition = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: transitionDuration },
    delay: transitionDelay,
    beforeChildren: true
  },
  exit: { opacity: 0, transition: { duration: transitionDuration } }
});

function Debug() {
  const [debugMode, setDebugMode] = useState(false);
  return (
    <>
      <DebugMode setDebugMode={setDebugMode} debugMode={debugMode} />
      {debugMode && <Ruler />}
    </>
  );
}

function Layout({ children, location }) {
  const [mounted, setMounted] = useState(false);
  const [home, setHome] = useState(false);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [revealChildren, setRevealChildren] = useState(true);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    if (window.location.pathname == "/") {
      setShowPageLoader(true);
      setRevealChildren(false);
      setHome(true);
    }
    setMounted(true);
    // window.addEventListener("scroll", () => {
    //   let top = window.pageYOffset || document.documentElement.scrollTop;
    //   if (top > 100 && !showScrollToTop) {
    //     setShowScrollToTop(true);
    //   }
    //   if (top <= 100 && showScrollToTop) {
    //     setShowScrollToTop(false);
    //   }
    // });
  }, []);

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "") {
      setHome(false);
    } else {
      setHome(true);
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <script type="text/javascript">
            {`window.prismic = 
                    { endpoint: 'https://anthem-wedderburn-landing.prismic.io/api/v2'
                    };`}
          </script>
          <script
            type="text/javascript"
            src="//static.cdn.prismic.io/prismic.min.js"
            async="true"
          />
        </Helmet>
        <GlobalStyles />
        {showPageLoader && <PageLoader setRevealChildren={setRevealChildren} />}
        <div
          style={{
            opacity: mounted ? 1 : 0,
            position: revealChildren ? "" : "fixed"
          }}
          id="top"
        >
          <Header
            revealChildren={revealChildren}
            home={home}
            location={location}
          />
          <PoseGroup>
            <Transition key={location.pathname}>
              <main>
                {React.cloneElement(children, {
                  revealChildren: revealChildren
                })}
              </main>
            </Transition>
          </PoseGroup>
          <Footer />
          {showScrollToTop && <ScrollToTop />}
          {/* <Debug /> */}
        </div>
      </>
    </ThemeProvider>
  );
}

export default Layout;
