import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  transition: opacity 1s ease-in;

  background-color: ${props => props.theme.colors.brand.rose};
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 300ms ease-in;
  min-width: 256px;
  max-width: 600px;
`;
