import styled from "styled-components";
import { hideVisually, rgba } from "polished";

export const Wrapper = styled.label`
  position: relative;
  display: inline-block;
  padding-left: 40px;
  font-size: 16px;
  line-height: 1.5;
  color: ${props => props.theme.colors.text.default};
  cursor: pointer;
  user-select: none;
`;

export const Input = styled.input.attrs({
  type: "radio"
})`
  ${hideVisually};
`;

export const Control = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.bg.default};
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
  background-size: 50% 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.alt};
  user-select: none;

  ${Input}:focus ~ & {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.brand.rose};
  }
  ${Input}:checked ~ & {
    background-color: ${props => props.theme.colors.brand.rose};
    box-shadow: inset 0 0 0 1px transparent;
  }
  ${Input}:active ~ & {
    background-color: ${props => rgba(props.theme.colors.brand.rose, 1 / 2)};
    box-shadow: inset 0 0 0 1px transparent;
  }
`;
