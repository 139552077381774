import React, { useState } from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import AriaModal from "react-aria-modal";

import { fluidType } from "utils";
import { H4, H6 } from "../headings";
import Button from "../button";

import WedderburnLogo from "../wedderburnLogo";

import { linkResolver } from "utils";

const SubnavItem = ({ label, to, onClick }) => (
  <H6
    color="text.reverse"
    onClick={onClick}
    fontSize={1}
    css={`
      position: relative;
      padding: 1.25em 2.5em;
    `}
  >
    <Link
      children={label}
      to={to}
      activeClassName="active"
      partiallyActive={true}
    />
  </H6>
);

const NavItem = ({ label, to, onClick, className }) => (
  <div
    onClick={onClick}
    as={to ? Link : "div"}
    to={to}
    activeClassName="active"
    partiallyActive={true}
    className={className}
    css={`
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      padding: 1.25em 2.5em;
    `}
  >
    <H6 children={label} color="text.reverse" fontSize={1} />
  </div>
);

function MobileMenu({ setMobileMenu, location }) {
  const [subnav, setSubnav] = useState([]);
  const [entered, setEntered] = useState(false);

  function close() {
    setEntered(false);
    setTimeout(() => {
      setMobileMenu(false);
      window.scrollTo(0, 0);
    }, 300);
  }

  return (
    <StaticQuery
      query={graphql`
        {
          prismicNavigation {
            dataString
          }
        }
      `}
      render={data => {
        let header = JSON.parse(data.prismicNavigation.dataString);
        return (
          <>
            <AriaModal
              titleText="menuPanel"
              onExit={close}
              onEnter={() => {
                setEntered(true);
              }}
              underlayStyle={{
                backgroundColor: entered
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(0, 0, 0, 0)",
                transition: "all 300ms ease-in-out"
              }}
              dialogStyle={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                minHeight: "100%",
                pointerEvents: "none"
              }}
              mounted={true}
            >
              <div
                className={
                  entered
                    ? subnav.length === 0
                      ? "entered"
                      : "entered subnav"
                    : ""
                }
                css={`
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left 0;
                  width: 100%;
                  background-color: ${props => props.theme.colors.brand.rose};
                  overflow: hidden;
                  pointer-events: all;
                  opacity: 0;
                  transition: opacity 500ms ease-out, background-color 500ms ease-out;

                  &.entered {
                    opacity: 1;
                  }

                  &.subnav {
                    background-color: ${props =>
                      props.theme.colors.brand.nickel};
                  }
                `}
              >
                <div>
                  <div
                    css={`
                      display: flex;
                      padding: 0 5vw;
                      margin-bottom: 1em;
                    `}
                  >
                    <Link
                      to="/"
                      css={`
                        margin: 20px 0;
                        ${fluidType(3)};
                      `}
                    >
                      <WedderburnLogo
                        css={`
                          width: ${(416 / 95) * 1.5 + "em"};
                          height: 1.5em;
                          vertical-align: top;
                        `}
                        onClick={() => {
                          setSubnav([]);
                          close();
                        }}
                      />
                    </Link>
                    <section
                      css={`
                        display: flex;
                        flex: 1;
                        justify-content: flex-end;
                      `}
                    >
                      <nav
                        css={`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        <button
                          onClick={close}
                          title="Close menu button"
                          css={`
                            width: 24px;
                            height: 24px;
                            margin: 0;
                            padding: 0;
                            border: 0;
                            border-radius: 0;
                            box-shadow: none;
                            background: transparent;
                            cursor: pointer;
                            &:focus {
                              outline: 0;
                            }
                          `}
                        >
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>X</title>
                            <path
                              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </nav>
                    </section>
                  </div>
                </div>
                {subnav.length === 0 && (
                  <section
                    css={`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <nav
                      css={`
                        display: flex;
                        flex-direction: column;
                      `}
                    >
                      {header.body.map((link, index) => (
                        <NavItem
                          label={link.primary.label}
                          to={
                            !link.items || !link.items[0]
                              ? linkResolver(link.primary.link)
                              : undefined
                          }
                          key={"fullNavLink" + index}
                          className={
                            link.items &&
                            link.items[0] &&
                            location.pathname.indexOf(
                              "/news-and-resources/"
                            ) !== -1 &&
                            "active"
                          }
                          onClick={() => {
                            if (link.items && link.items[0]) {
                              if (subnav.length === 0) {
                                setSubnav(link.items);
                              } else {
                                setSubnav([]);
                              }
                            } else {
                              setSubnav([]);
                              close();
                            }
                          }}
                        />
                      ))}
                    </nav>
                    <div
                      css={`
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                      `}
                    >
                      {header.registration_link &&
                        header.registration_button_text && (
                          <Button
                            children={header.registration_button_text}
                            as={Link}
                            to={linkResolver(header.registration_link)}
                            variant="reverseAlt"
                            style={{ margin: "auto 0" }}
                            onClick={() => {
                              setSubnav([]);
                              close();
                            }}
                            style={{
                              width: "100%",
                              padding: "2em 2em"
                            }}
                          />
                        )}
                    </div>
                  </section>
                )}
                <div
                  css={`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  {subnav.length > 0 && (
                    <nav>
                      {subnav.map((subnav, index) => (
                        <SubnavItem
                          label={subnav.sublabel}
                          to={linkResolver(subnav.sublink)}
                          key={"fullNavLink" + index}
                          onClick={() => {
                            setSubnav([]);
                            close();
                          }}
                        />
                      ))}
                      <button
                        onClick={() => {
                          setSubnav([]);
                        }}
                        css={`
                          position: absolute;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          margin: 0;
                          padding: 3em 2.5em;
                          border: 0;
                          border-radius: 0;
                          box-shadow: none;
                          background: transparent;
                          cursor: pointer;
                          &:focus {
                            outline: 0;
                          }
                        `}
                      >
                        <svg
                          width="12"
                          height="20"
                          viewBox="0 0 12 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.9629 1.87001L3.18645 9.64645L2.83289 10L3.18645 10.3536L10.9629 18.13L9.9 19.1929L0.707107 10L9.9 0.807113L10.9629 1.87001Z"
                            fill="white"
                            stroke="white"
                          />
                        </svg>
                        <H6
                          children="Back"
                          color="text.reverse"
                          css={`
                            margin-left: 8px;
                          `}
                        />
                      </button>
                    </nav>
                  )}
                </div>
              </div>
            </AriaModal>
          </>
        );
      }}
    />
  );
}

export default MobileMenu;
