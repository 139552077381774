import React from "react";
import { RichText } from "prismic-reactjs";

import linkResolver from "./linkResolver";

const Elements = RichText.Elements;

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function(type, element, content, children, key) {
  var props = {};
  switch (type) {
    case Elements.preformatted:
      return React.createElement(
        "blockquote",
        propsWithUniqueKey(props, key),
        children
      );
    // Return null to stick with the default behavior
    default:
      return null;
  }
};

export default htmlSerializer;
