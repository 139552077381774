import React, { useState, useEffect } from "react";
import Vivus from "vivus";

import { WedderburnLogoSeperated } from "components";

import { Overlay, LogoWrapper } from "./style";

function PageLoader({ setRevealChildren }) {
  const [mounted, setMounted] = useState(false);
  const [isRevealed, setRevealed] = useState(false);
  const [isFaded, setFaded] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      new Vivus(
        "bigLogo",
        { duration: 85, start: "autostart", type: "sync" },
        () => {
          setRevealed(true);
          setTimeout(() => {
            setRevealChildren(true);
            setFaded(true);
          }, 600);
        }
      );
    }
  }, [mounted]);

  return (
    <Overlay
      style={{
        opacity: isFaded ? 0 : 1,
        pointerEvents: isFaded ? "none" : "all"
      }}
    >
      {mounted && (
        <>
          <LogoWrapper>
            <WedderburnLogoSeperated id="bigLogo" hideLogo={true} />
          </LogoWrapper>
          <LogoWrapper style={{ opacity: isRevealed ? 1 : 0 }}>
            <WedderburnLogoSeperated style={{ fill: "#fff" }} />
          </LogoWrapper>
        </>
      )}
    </Overlay>
  );
}

export default PageLoader;
