import styled from "styled-components";
import { triangle } from "polished";

import { fluidType } from "utils";

const borderWidth = 1;
const borderRadii = 0;

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  user-select: none;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 24px;
    ${props =>
      triangle({
        pointingDirection: "bottom",
        width: "12px",
        height: "6px",
        foregroundColor: props.theme.colors.text.default
      })};
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const Comp = styled.select`
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0.5em 3em 0.5em 1em;
  border-width: ${borderWidth + "px"};
  border-style: solid;
  border-color: ${props => props.theme.colors.bg.reverse};
  border-radius: ${borderRadii + "px"};
  outline: 0;
  ${fluidType(1)};
  line-height: 1.5;
  color: ${props => props.theme.colors.text.default};
  background-color: ${props => props.theme.colors.bg.default};
  cursor: pointer;
  appearance: none;

  &:focus {
    border-color: ${props => props.theme.colors.brand.rose};
  }
  &:active {
    border-color: ${props => props.theme.colors.brand.rose};
  }

  /* Hide the arrow in IE10 and up */
  &::-ms-expand {
    display: none;
  }
  /* Undo the Firefox inner focus ring */
  &:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;
