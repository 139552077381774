import React from "react";

const Column = ({ ...rest }) => (
  <div
    css={`
      position: relative;

      -ms-grid-row: 1;
      grid-row: 1;

      height: 100vh;

      &::before {
        content: "";

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: 1px;
        height: 100%;

        background-color: rgba(255, 0, 0, 0.125);
      }

      &::after {
        content: "";

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: 1px;
        height: 100%;

        background-color: rgba(255, 0, 0, 0.125);
      }
    `}
    {...rest}
  />
);

const Ruler = () => (
  <div
    css={`
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100000;

      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
        ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${props => props.theme.gutter};

      padding: 0 ${props => props.theme.margin};

      pointer-events: none;

      ${props => props.theme.mediaQueries.medium} {
        -ms-grid-columns: 1fr ${props => props.theme.gutter} 1fr
          ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
          ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr
          ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr;
        grid-template-columns: repeat(8, 1fr);

        *:nth-last-child(-n + 4) {
          display: none;
        }
      }

      ${props => props.theme.mediaQueries.small} {
        -ms-grid-columns: 1fr ${props => props.theme.gutter} 1fr
          ${props => props.theme.gutter} 1fr ${props => props.theme.gutter} 1fr;
        grid-template-columns: repeat(4, 1fr);
        padding-right 10vw;
        padding-left 10vw;

        *:nth-last-child(-n + 8) {
          display: none;
        }
      }
    `}
  >
    <Column
      css={`
        -ms-grid-column: 1;
        grid-column: 1;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 3;
        grid-column: 2;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 5;
        grid-column: 3;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 7;
        grid-column: 4;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 9;
        grid-column: 5;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 11;
        grid-column: 6;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 13;
        grid-column: 7;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 15;
        grid-column: 8;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 17;
        grid-column: 9;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 19;
        grid-column: 10;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 21;
        grid-column: 11;
      `}
    />
    <Column
      css={`
        -ms-grid-column: 23;
        grid-column: 12;
      `}
    />
  </div>
);

export default Ruler;
