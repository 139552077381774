import React, { useState } from "react";
import Helmet from "react-helmet";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { createGlobalStyle } from "styled-components";

import { Box, FlexCol, FlexRow, Text } from "components";

import House from "./_house";
import AnthemUnitedLogo from "../anthemUnitedLogo";

const GlobalStyles = createGlobalStyle`
  .popup[data-reach-dialog-overlay] {
    z-index: 9999 !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .popup [data-reach-dialog-content] {
    width: 90% !important;
    max-width: 768px !important;
    margin: 20vh auto !important;
    padding: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #fff;
    background-color: #000;
  }
  @media (max-width: 600px) {
    .covid19 [data-reach-dialog-content] {
      margin: 10vh auto !important;
    }
  }
`;

function Popup({ text, buttonText, buttonLink }) {
  const [showDialog, setShowDialog] = useState(true);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <>
      <GlobalStyles />

      <DialogOverlay className="popup" isOpen={showDialog} onClick={close}>
        <DialogContent>
          <Box position="relative" px={[24, 64]}>
            <Box
              position="absolute"
              left={0}
              top={24}
              bg="#51ebcd"
              pl="24px"
              pr="16px"
              py={0}
              height="48px"
            >
              <Box
                as="p"
                color="#000000"
                border="0"
                fontFamily="Arial"
                fontWeight={900}
                fontSize={4}
                mt="10px"
                letterSpacing={"0.05em"}
              >
                On Now
              </Box>
            </Box>
            <Box position="relative" py={[64, 48]}>
              <button
                css={`
                  position: absolute;
                  top: 48px;
                  right: 0;
                  margin: 0;
                  padding: 0;
                  border: 0;
                  background: 0;
                  appearance: none;
                  outline: 0;
                  cursor: pointer;
                `}
                onClick={close}
              >
                <svg width="24" height="24" viewBox="0 0 94 94" fill="none">
                  <path
                    d="M47 49.6569L90.6812 93.3381L93.5097 90.5097L49.8284 46.8284L93.3381 3.31876L90.5097 0.490334L47 44L3 0L0.171573 2.82843L44.1716 46.8284L0 91L2.82843 93.8284L47 49.6569Z"
                    fill="white"
                  />
                </svg>
              </button>
              <FlexCol mb={4} mt={[6, 4]}>
                <FlexRow justifyContent="center" mb={[3, 0, -6]}>
                  <Box width="256px">
                    <AnthemUnitedLogo invert />
                  </Box>
                </FlexRow>
                <Text
                  color="#51ebcd"
                  fontFamily="Arial"
                  fontWeight={700}
                  fontSize={9}
                  textAlign="center"
                  letterSpacing={"0.1em"}
                  style={{ height: "1em" }}
                >
                  $50K
                </Text>
                <Text
                  textAlign="center"
                  mt={4}
                  color="inherit"
                  fontFamily="Arial"
                  fontWeight={700}
                  fontSize={6}
                >
                  Giveaway
                </Text>
                <FlexRow justifyContent="center" mt={6}>
                  <Box
                    as="a"
                    href="https://www.anthemgiveaway.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                    bg="#51ebcd"
                    color="#000000"
                    border="0"
                    py={2}
                    px={4}
                    fontFamily="Arial"
                    fontWeight={900}
                    fontSize={3}
                    letterSpacing={"0.05em"}
                    style={{ textTransform: "uppercase" }}
                  >
                    I want to win!
                  </Box>
                </FlexRow>
                <FlexRow
                  flexDirection={"row"}
                  // justifyContent={["flex-start", "space-between"]}
                  justifyContent={"center"}
                  mt={3}
                >
                  <Text
                    mb={[3, 0]}
                    textAlign="center"
                    color="inherit"
                    fontFamily="Arial"
                    fontWeight={700}
                    style={{ fontSize: 14 }}
                  >
                    <a
                      href="https://anthemgiveaway.ca/wordpress/wp-content/uploads/2023/09/Terms-and-Conditions-Anthem-Giveaway-2023.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Rules and Regulations apply.
                    </a>
                  </Text>
                </FlexRow>
              </FlexCol>
            </Box>
          </Box>
        </DialogContent>
      </DialogOverlay>
    </>
  );
}

export default Popup;
