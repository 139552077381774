import React from "react";
import PropTypes from "prop-types";

import Image from "../image";
import Section from "../section";

import { Wash } from "./style";

const PageHeader = ({ bg, alt, fluid, image, contain, src, ...rest }) => (
  <Section
    pt={1}
    bg="bg.default"
    css={`
      ${props => props.theme.mediaQueries.small} {
        padding-top: 0;
      }
    `}
    {...rest}
  >
    <div
      css={`
        -ms-grid-column: 1;
        -ms-grid-column-span: 23;
        grid-column: 1 / span 12;
        -ms-grid-row: 1;
        grid-row: 1;
        ${props => props.theme.mediaQueries.medium} {
          -ms-grid-column: 1;
          -ms-grid-column-span: 15;
          grid-column: 1 / 9;
        }
        ${props => props.theme.mediaQueries.small} {
          -ms-grid-column: 1;
          -ms-grid-column-span: 7;
          grid-column: 1 / 5;
          margin-right: -10vw;
          margin-left: -10vw;
        }
      `}
    >
      <Wash bg={bg} />
      <Image
        src={src}
        fluid={fluid}
        image={image}
        alt={alt}
        loading="eager"
        css={`
          object-fit: contain;
          height: 67vh;
          ${props => props.theme.mediaQueries.small} {
            height: 40vh;
          }
        `}
        objectFit={contain && "contain"}
      />
    </div>
  </Section>
);

PageHeader.propTypes = {
  image: PropTypes.string.isRequired
};

PageHeader.defaultProps = {
  bg: "brand.aqua",
  image: "family01"
};

export default PageHeader;
